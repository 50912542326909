const default_lang = process.env.LANG;

const MENU_BTN0 = (lang) => {
  switch (lang) {
    case 'eng':
      return 'HOME';
    case 'esp':
      return 'INICIO';
    default:
      return MENU_BTN1(default_lang);
  }
};

const MENU_BTN1 = (lang) => {
  switch (lang) {
    case 'eng':
      return 'INFORMATION';
    case 'esp':
      return 'INFORMACIÓN';
    default:
      return MENU_BTN1(default_lang);
  }
};

const MENU_BTN2 = (lang) => {
  switch (lang) {
    case 'eng':
      return 'CURRICULUM';
    case 'esp':
      return 'CURRÍCULA';
    default:
      return MENU_BTN2(default_lang);
  }
};

const MENU_BTN3 = (lang) => {
  switch (lang) {
    case 'eng':
      return 'SIGN UP';
    case 'esp':
      return 'INSCRIPCIÓN';
    default:
      return MENU_BTN3(default_lang);
  }
};

const MENU_BTN4 = (lang) => {
  switch (lang) {
    case 'eng':
      return 'SIGN IN';
    case 'esp':
      return 'INGRESA';
    default:
      return MENU_BTN4(default_lang);
  }
};

export { MENU_BTN0, MENU_BTN1, MENU_BTN2, MENU_BTN3, MENU_BTN4 };

import * as R from 'ramda';
import Button from '../../componentes/utils/Button';
import FotografiaDialog from './FotografiaDialog';
import Grid from '@material-ui/core/Grid';
import React from 'react';

import { UPLOAD_PHOTO } from './api';
import { sesionStore } from '../../utils/sesionStore';
import * as store from '../../utils/localStore';
import { Avatar, Badge } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  editButton: {
    width: 25,
    height: 25,
    border: 'none',
    borderRadius: '50%',
    backgroundColor: '#EAE9EC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  avatar: {
    width: 100,
    height: 100,
    backgroundColor: '#D4A6DE',
  },
  editIcon: {
    color: '#6E6779',
    width: 18,
    height: 18,
  },
}));

const Fotografia = ({ setAlerta }) => {
  const global = React.useContext(sesionStore);
  const { sesion } = global;
  const [subirFotoDialog, setSubirFotoDialog] = React.useState(false);
  const [dialogStepper, setDialogStepper] = React.useState(0);
  const [fotografia, setFotografia] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleImage = () => {
    const file = document.getElementById('imginput').files[0];
    if (
      !R.includes(file.type, [
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/bmp',
      ])
    ) {
      setAlerta({
        open: true,
        message:
          'Los formatos soportados para este archivo son: JPEG, JPG, PNG, BMP',
        type: 'error',
      });
    } else if (file.size > 2000000) {
      setAlerta({
        open: true,
        message:
          'El peso de la fotografía no puede ser mayor a 2mb. (La suya pesa ' +
          file.size / 1000000 +
          'mb)',
        type: 'error',
      });
    } else {
      setFotografia({
        url: URL.createObjectURL(file),
        raw: file,
      });
      setLoading(true);
    }
  };

  const uploadImage = async () => {
    try {
      const formData = new FormData();
      formData.append('fotografia', fotografia?.raw);
      const token = store.getObject('token');
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };

      await UPLOAD_PHOTO(formData, sesion?.usuario, config);
      setAlerta({
        open: true,
        message: 'La foto ha sido actualizada con éxito',
        type: 'success',
      });
    } catch (error) {
      console.log('Error', error.response?.data?.message || error);
      setAlerta({
        open: true,
        message: 'Ocurrió un error inesperado, por favor inténtalo más tarde',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const classes = useStyles();

  return (
    <Box>
      <Badge
        overlap='circular'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <button
            onClick={() => setSubirFotoDialog(true)}
            className={classes.editButton}>
            <Edit className={classes.editIcon} />
          </button>
        }>
        <Avatar
          className={classes.avatar}
          alt={sesion?.nombres}
          src={`${process.env.BUCKET_S3}/${sesion?.foto}`}
        />
      </Badge>
      <FotografiaDialog
        openDialog={subirFotoDialog}
        cancelar={() => {
          setSubirFotoDialog(false);
          setFotografia(null);
          setDialogStepper(0);
        }}
        finalizar={() => {
          setSubirFotoDialog(false);
          setFotografia(null);
          setDialogStepper(0);
          setTimeout(() => window.location.reload(), 1000);
        }}
        setFotografia={setFotografia}
        activeStep={dialogStepper}
        fotografia={fotografia}
        setActiveStep={setDialogStepper}
        uploadImage={uploadImage}
        handleImage={handleImage}
        loading={loading}
      />
    </Box>
  );
};

export default Fotografia;

import React, { Suspense, lazy } from 'react';
import Loading from '@material-ui/core/LinearProgress';

//Components
import Head from '../Head';
import Portada from '../subvistas/Common/Portada';
const Seccion = lazy(() => import('../subvistas/Common/Seccion'));
// import Portada from '../subvistas/Home/Portada';

//images
import cover from '../imagenes/banner01.png';
import vision from '../imagenes/vision.jpg';
import quienes from '../imagenes/quienessomos.jpg';

const Vision = lazy(() => import('../subvistas/Home/Vision'));
const Consideracion = lazy(() => import('../subvistas/Home/Consideracion'));
const ArrowDown = lazy(() => import('../componentes/utils/ArrowDown'));

function Home() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Head titulo='Inicio' />
      <Portada
        titulo='Abre la puerta a una mejor educación desde tu hogar.'
        descripcion='Educación en línea para hispanohablantes en todo el mundo desde hace más de 15 años.'
        img={cover}
        img_alt=''
        color='#434343'
        bg_color='#f7c300'
      />

      <Suspense fallback={<Loading />}>
        <Seccion
          titulo='Visión'
          subtitulo='Escuela en casa / Homeschool'
          descripcion={
            <React.Fragment>
              Somos una plataforma educativa línea comprometida con ayudar a
              los padres a desarrollar en sus estudiantes las habilidades
              académicas que necesitan para una <b>educación integral</b>.
              <br />
              <br />
              Formamos personas <b>autodidactas</b>, con pensamiento crítico y
              analítico, familiarizadas con el mundo digital. Facultades
              indispensables para enfrentar el mundo de hoy.
            </React.Fragment>
          }
          img={vision}
          img_alt=''
        />
        <Vision />
        <Consideracion />
        <Seccion
          titulo='¿Quiénes somos?'
          subtitulo='Educación enfocada a la vida'
          descripcion={
            <React.Fragment>
              Somos una Asociación Civil sin fines de lucro, comprometida con el{' '}
              <b>desarrollo educativo</b> de nuestra niñez y juventud de habla
              hispana de cualquier país del mundo.
              <br />
              <br />
              Nuestra labor social es ofrecer una formación educativa de alto
              nivel a muy bajo costo, por lo que nuestro único objetivo
              económico es que este portal sea autosustentable mientras
              aseguramos la <b>mejor educación</b> para todos.
            </React.Fragment>
          }
          img={quienes}
          img_alt=''
          direction='row-reverse'
        />
        <ArrowDown />
      </Suspense>
    </div>
  );
}

export default Home;

import React from 'react';
import { Suspense, lazy } from 'react';
import Loading from '@material-ui/core/LinearProgress';

//Components
import Head from '../Head';
import Portada from '../subvistas/Common/Portada';
const Seccion = lazy(() => import('../subvistas/Common/Seccion'));

import cover from '../imagenes/banner03.png';
import certificacion from '../imagenes/certificacion.jpg';

const FAQ = lazy(() => import('../subvistas/Home/FAQ'));
const Costos = lazy(() => import('../subvistas/Informacion/Costos'));
const ArrowDown = lazy(() => import('../componentes/utils/ArrowDown'));

function Informacion() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Head titulo='Información' />
      <Portada
        titulo='Inspiración Cristiana'
        descripcion={
          <React.Fragment>
            En educazion.net, nuestros métodos y pedagogía tienen un sello
            característico que nace de la admiración por las virtudes
            cristianas, siendo el <b>respeto</b> y la <b>dignidad</b> humana
            pilares fundamentales.
            <br />
            <br />
            Nuestro plan de estudios tiene como objetivo formar alumnos
            integrales y competentes para el mundo académico y laboral, además
            de ser capaces de pensar en el <b>bien común</b>.
          </React.Fragment>
        }
        img={cover}
        img_alt=''
        color='#434343'
        bg_color='#b877bb'
      />

      <Suspense fallback={<Loading />}>
        <Seccion
          titulo='Calidad Nacional e Internacional'
          subtitulo='Estándares Globales'
          bg_color='rgb(240,240,240)'
          descripcion={
            <React.Fragment>
              Nuestro plan educativo está avalado y{' '}
              <b>certificado internacionalmente</b> desde Estados Unidos, por el
              estado de Florida. Por lo que la educación de tus hijos tendrá la
              mejor calidad con estándares globales.
              <br />
              <br />
              En México contamos con Reconocimiento de Validez Oficial de
              Estudios (RVOE), emitida por la Dirección Nacional de
              Bachilleratos de la SEP. Así que no te preocupes y disfruta viendo
              a tus hijos crecer en todos los aspectos con nuestros contenidos
              educativos.
            </React.Fragment>
          }
        />

        <Seccion
          titulo='Certificaciones'
          subtitulo='¿Qué documentos se obtienen?'
          img={certificacion}
          direction='row-reverse'
          img_alt='Preparatoria en linea con tutores personalizados.'
          descripcion={
            <React.Fragment>
              <b>Primaria/Secundaria (1ro a 9no)</b>
              <br />
              Al terminar cada grado escolar, se emite la boleta del estudiante
              certificada desde nuestra sede en Estados Unidos, la cual, se puede
              validar en México a través de la SEP (es necesario cursar el
              70% de nuestra currícula).
              <br />
              <br />
              <b>Preparatoria/Bachillerato (12vo)</b>
              <br />
              Al terminar el programa, se emite la certificación de High School
              desde nuestra sede en Estados Unidos. Para mexicanos también se
              emite la certificación SEP.
            </React.Fragment>
          }
        />

        <Seccion
          titulo='Invierte sabiamente en la educación'
          subtitulo='Estándares Globales'
          bg_color='#26c6da'
          descripcion={
            <React.Fragment>
              Creemos firmemente que la educación es pilar en el{' '}
              <b>desarrollo</b> de la sociedad, por eso incentivamos a los
              padres a invertir en una mejor educación para sus hijos.
              <br />
              <br />
              Siendo la educación un derecho, en donde las instituciones
              privadas o públicas están <b>obligadas</b> a inscribir a
              estudiantes aunque no cuenten con boletas nacionales; hemos
              decidido no promover la compra o trámite de documentación
              innecesaria para el desarrollo escolar.
              <br />
              <br />
              Si eres o resides en México, te invitamos a conocer las Normas de
              Control Escolar de la SEP para conocer a profundidad los derechos
              estudiantiles y las posibilidades de revalidación.
            </React.Fragment>
          }
        />

        <Costos />
        <FAQ />
        <ArrowDown />
      </Suspense>
    </React.Fragment>
  );
}

export default Informacion;

import Seccion from '../../componentes/Cuenta/Seccion';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { sesionStore } from '../../utils/sesionStore';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@material-ui/icons/Print';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ViewList from '@material-ui/icons/ViewList';

const useStyles = makeStyles(() => ({
  approveButton: {
    backgroundColor: '#3EBCE8',
    padding: '8px 16px',
    borderRadius: '8px',
    color: '#fff',
    fontWeight: 'bold',
  },
}));

const Historial = ({
  expanded,
  handleChange,
  width,
  handleMaterias,
  handleMessage,
  openDownloadOptions,
  setDialog,
  aprobar,
}) => {
  const global = React.useContext(sesionStore);
  const { sesion } = global;
  const classes = useStyles();

  return (
    <Seccion
      title='Historial Académico'
      id='1'
      expanded={expanded}
      onChange={handleChange}>
      <div style={{ width: '100%' }}>
        <Grid container justifyContent='center' alignItems='center'>
          {width === 'sm' || width === 'xs' ? null : (
            <Grid item xs={2}>
              <Typography variant='body1' align='center'>
                <b>Fecha</b>
              </Typography>
            </Grid>
          )}
          <Grid item xs={4} md={2}>
            <Typography variant='body1' align='center'>
              <b>Currícula</b>
            </Typography>
          </Grid>
          <Grid item xs={4} md={2}>
            <Typography variant='body1' align='center'>
              <b>Calificaciones</b>
            </Typography>
          </Grid>
          <Grid item xs={4} md={2}>
            <Typography variant='body1' align='center'>
              <b>Boleta</b>
            </Typography>
          </Grid>
        </Grid>
        {sesion?.historialAcademico?.length > 0
          ? sesion?.historialAcademico?.map((ciclo, index) => {
              const date = new Date(ciclo.fecha);
              const fecha =
                date.getDate() +
                '/' +
                (date.getMonth() + 1) +
                '/' +
                date.getFullYear();
              let promedio = 0;
              let noMaterias = 0;
              ciclo.materias.map((materia) => {
                noMaterias++;
                promedio += materia.calificacion;
              });
              return (
                <Grid
                  container
                  justifyContent='center'
                  alignItems='center'
                  key={index}>
                  {width === 'sm' || width === 'xs' ? null : (
                    <Grid item md={2}>
                      <Typography variant='body1' align='center'>
                        {fecha}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={4} md={2}>
                    <Typography variant='body1' align='center'>
                      {ciclo.periodo}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    md={2}
                    style={{ display: 'flex', justifyContent: 'center' }}>
                    <IconButton onClick={(e) => handleMaterias(e, ciclo)}>
                      <ViewList />
                    </IconButton>
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <Typography variant='body1' align='center'>
                      {ciclo.autorizado ? (
                        ciclo.aprobado ? (
                          <IconButton
                            onClick={(e) => openDownloadOptions(e, ciclo)}>
                            <PrintIcon />
                          </IconButton>
                        ) : (
                          <Button
                            className={classes.approveButton}
                            onClick={(e) =>
                              setDialog({
                                message:
                                  '¿Está seguro que desea aprobar la boleta de calificaciones? Una vez hecho esto, no se puede hacer ningun cambio o aclaración.',
                                open: true,
                                process: () => aprobar(index),
                                title: 'Confirmación',
                              })
                            }>
                            Aprobar ciclo
                          </Button>
                        )
                      ) : (
                        'EN ESPERA'
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })
          : 'Aún no has finalizado ningún curso.'}
      </div>
    </Seccion>
  );
};

export default Historial;

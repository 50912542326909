import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

//Components
import Head from '../Head';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Footer from '../componentes/Footer';
import faq from '../imagenes/faq.png';
//Icons
import Facebook from '@material-ui/icons/Facebook';
import Instagram from '@material-ui/icons/Instagram';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    padding: '100px 10%',
    textAlign: 'center',
  },
  iconImg: {
    width: '64px',
  },
  logo: {
    width: '172px',
  },
  logoContainer: {
    textAlign: 'center',
  },
}));

function Mantenimiento() {
  const classes = useStyles();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [true]);

  return (
    <React.Fragment>
      <Head titulo='Mantenimiento' />
      <div className={classes.root}>
        <img src={faq} />
        <Typography variant='h3'>
          Estamos trabajando en el mantenimiento del sitio
        </Typography>
        <br />
        <Typography variant='h6'>
          Puedes acceder a la plataforma de estudios desde{' '}
          <a href='https://aulas.educazion.net/'>
            <u>aquí</u>
          </a>
          .
          <br />
          Si tienes una duda adicional, puedes contactarnos en{' '}
          <a href='https://discord.gg/4XJtuT8k2D'>
            <u>Discord</u>
          </a>
          .
        </Typography>
        <br />
        <br />
        <Typography variant='body1'>
          También nos puedes contactar en nuestras redes sociales:
        </Typography>
        <Grid container>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <a href='https://www.facebook.com/educazionOficial' target='_blank'>
              <IconButton>
                <Facebook fontSize='large' />
              </IconButton>
            </a>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'left' }}>
            <a
              href='https://www.instagram.com/comunidad.educazion/'
              target='_blank'>
              <IconButton>
                <Instagram fontSize='large' />
              </IconButton>
            </a>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Mantenimiento;

import React from 'react';

//Images
import PreescolarImg from '../imagenes/primariaPortada.png';
import PrimariaImg from '../imagenes/preescolarPortada.png';
import SecundariaImg from '../imagenes/secundariaPortada.png';

import PreescolarImg2 from '../imagenes/hector/14.jpg';
import PrimariaImg2 from '../imagenes/hector/22.jpg';
import SecundariaImg2 from '../imagenes/hector/21.jpg';

const niveles = [
  {
    nivel: 'Preescolar',
    descripcion: (
      <React.Fragment>
        {' '}
        Es una edad en la que los pequeños aman el aprendizaje, desean descubrir
        el mundo que les rodea; todo lo preguntan y todo lo cuestionan. Nuestro
        sistema está diseñado para que descubran las habilidades necesarias para
        emprender su formación cognitiva e intelectual. <br />
        <br />
      </React.Fragment>
    ),
    color: '#2196f3',
    secondaryColor: '#42a5f5',
    img: PreescolarImg,
    imgS: PreescolarImg2,
    edad: 'De 3 a 5 años',
    costoSoporte: 500,
    Habilidades: [
      {
        titulo: 'Emocional',
        grados: [
          {
            grado: 'Carácter',
            descripcion:
              'Desarrollar carácter en nuestros pequeños no es una tarea fácil; \
                    a lo largo del ciclo escolar les proveemos a los padres las herramientas\
                    y procesos de entrenamiento que les permiten\
                    moldear el carácter de sus estudiantes.',
          },
          {
            grado: 'Inteligencia Emocional',
            descripcion:
              'Hemos incluido en esta etapa el desarrollo de la inteligencia emocional,\
                    porque nuestros niños necesitan conocer sus emociones y cómo funcionan;\
                    tales como el enojo, la frustración, el gozo,\
                    la ira, etc.',
          },
          {
            grado: 'Habilidades Sociales',
            descripcion:
              'Es muy importante que aprendan a relacionarse correctamente\
                    con personas de diferentes edades. Nuestra currícula proveé técnicas de entrenamiento\
                    para desarrollarse profundamente en el área social.',
          },
        ],
      },
      {
        titulo: 'Intelectual',
        grados: [
          {
            grado: 'Conocimiento',
            descripcion:
              'Es la edad de mayor aprendizaje\
                    el ejercicio literario es lo mejor que podemos hacer\
                    ya que ampliaremos no solo el área de lenguaje\
                    también entrenaremos los sentidos\
                    para que sean receptivos\
                    al proceso de aprendizaje.',
          },
          {
            grado: 'Caligrafía',
            descripcion:
              'Iniciamos con el proceso de la preescritura\
                    tomando como fuente el dibujo,la coordinación,\
                    expresión corporal y caligrafía.',
          },
          {
            grado: 'Espacio - Temporal',
            descripcion:
              'Trabajaremos con ubicación de espacios,\
                    movimiento y coordinación corporal\
                    para un desarrollo motriz.',
          },
          {
            grado: 'Lectoescritural',
            descripcion:
              'En tercer grado de preescolar\
                    nuestros estudiantes aprenden a leer y escribir fluidamente,\
                    así como sumar y restar.',
          },
        ],
      },
    ],
  },
  {
    nivel: 'Primaria',
    descripcion: (
      <React.Fragment>
        {' '}
        Nuestro programa académico tiene un enfoque global, así que, sin
        importar el lugar de residencia, lo que buscamos es desarrollar
        competencias y habilidades para un mundo global.
      </React.Fragment>
    ),
    color: '#26c6da',
    secondaryColor: '#4dd0e1',
    img: PrimariaImg,
    imgS: PrimariaImg2,
    edad: 'De 5 a 11 años',
    costoSoporte: 700,
    Materias: [
      {
        titulo: 'Español',
        grados: [
          {
            grado: 'I',
            descripcion:
              'Durante el curso escolar, analizamos un conjunto de lecturas\
                    en las cuales se describen situaciones de la vida cotidiana,con ayudas visuales,\
                    los estudiantes podrán entender los conceptos de las lecturas\
                    con mayor facilidad.',
          },
          {
            grado: 'II',
            descripcion:
              'Leer para conocer el pensamiento y las ideas de otros,\
                    para así construir en conjunto nuevas formas de pensamiento.\
                    A través de las lecturas y los ejercicios,\
                    iremos desarrollando habilidades fundamentales\
                    para la comprensión lectora y la escritura.',
          },
          {
            grado: 'III',
            descripcion:
              'Conocemos los principios de ortografía\
                    los cuales iremos aplicando en la producción de textos\
                    para poder expresar ideas claras.',
          },
          {
            grado: 'IV',
            descripcion:
              'Por medio de lectura y redacción dirigimos a nuestros estudiantes\
                    hacia un desarrollo de habilidades y actitudes de comunicación,\
                    fomentando las  relaciones interpersonales basadas en el respeto.',
          },
          {
            grado: 'V',
            descripcion:
              'Utilizamos la redacción y la producción de textos para narrar el mundo\
                    y poner en palabras nuestros pensamientos, sentimientos e ideas. ',
          },
          {
            grado: 'VI',
            descripcion:
              'La expresión lectoescritora nos permite comunicarnos,\
                    rompiendo así las barreras del tiempo y el espacio,\
                    promovemos que los estudiantes se comuniquen de manera correcta y ética.',
          },
        ],
      },
      {
        titulo: 'Matemáticas',
        grados: [
          {
            grado: 'I',
            descripcion:
              'Estudiaremos: los números del 1 al 100, unidades, decenas y centenas;\
                    series, sumas y restas; comparación de números, mayor y menor que;\
                    la hora, el calendario, unidades de medición, simetría,\
                    números romanos del I al C y ejercicos de agilidad mental.',
          },
          {
            grado: 'II',
            descripcion:
              'Reforzaremos: los números del 1 al 100, unidades, decenas y centenas;\
                    series, sumas y restas; comparación de números, mayor y menor que;\
                    la hora, el calendario, unidades de medición, simetría,\
                    números romanos del I al C y ejercicos de agilidad mental.',
          },
          {
            grado: 'III',
            descripcion:
              'Los números del 1 al 10000. Unidades, decenas y centenas,\
                    unidades de millar, multiplicación y división;\
                    fracciones sencillas: mitad, cuarto, medio, series;\
                    comparación de números, mayor y menor que, conjuntos,\
                    el reloj, medición del tiempo, unidades de medida: litros, kilos;\
                    cuerpos geométricos, signos de agrupación, formas y figuras;\
                    simetría, mapas, puntos cardinales.',
          },
          {
            grado: 'IV',
            descripcion:
              'Cálculo mental, solución de problemas, unidades de medidas,\
                    números romanos, signos de agrupación, millares;\
                    área, perímetro y volumen de figuras, fracciones, polígonos y líneas;\
                    finalizando con intepretación de escalas en mapas.',
          },
          {
            grado: 'V',
            descripcion:
              'Cálculo mental, solución de problemas, unidades de medidas;\
                    números romanos, signos de agrupación, unidad de millar,\
                    decenas de millar y centenas de millar, gráficas y datos;\
                    conjuntos, área y perímetro de figuras básicas, series numéricas;\
                    fracciones, porcentajes, equivalencias y ejercicios de lógica.',
          },
          {
            grado: 'VI',
            descripcion:
              'Reforzaremos cálculo mental, solución de problemas, unidades de medidas;\
                    números romanos, signos de agrupación, unidad de millar,\
                    decenas de millar y centenas de millar, gráficas y datos;\
                    conjuntos, área y perímetro de figuras básicas, series numéricas;\
                    fracciones, porcentajes, equivalencias y ejercicios de lógica.',
          },
        ],
      },
      {
        titulo: 'Ciencias',
        grados: [
          {
            grado: 'I',
            descripcion:
              'Comenzaremos por un conocimiento general sobre nuestro entorno,\
                    nuestro cuerpo y sus valores fundamentales.',
          },
          {
            grado: 'II',
            descripcion:
              '¿Cómo funcionan los seres vivos? Explorando el mundo en que vivimos,\
                    conoceremos la diversidad de la flora y fauna y sus funciones. ',
          },
          {
            grado: 'III',
            descripcion:
              'Estudiaremos las funciones básicas de los seres vivos,\
                    como la respiración, la alimentación y el ciclo de la vida.',
          },
          {
            grado: 'IV',
            descripcion:
              'Estudiaremos diferentes tipos de organismos\
                    y algunas funciones del cuerpo humano. ',
          },
          {
            grado: 'V',
            descripcion:
              'Existe una gran diversidad biológica a nuestro alrededor,\
                    grandes animales, pequeñas células, incluso nuestro propio organismo,\
                    estudiaremos cómo todo está relacionado,\
                    cómo todo cambia y cómo funcionan cada uno.',
          },
          {
            grado: 'VI',
            descripcion:
              'Estudiaremos la complejidad del cuerpo humano,\
                    el conjunto de células, órganos, tejidos y otros elementos,\
                    procesos y funciones que relacionamos con nuestro entorno y su cuidado.',
          },
        ],
      },
      {
        titulo: 'Historia',
        grados: [
          {
            grado: 'III',
            descripcion:
              'Aprenderemos a ubicarnos en el tiempo, conociendo nuestra propia historia\
                    y cómo medirlo a través de la línea del tiempo, el árbol genealógico,\
                    el calendario y el reloj. Además,\
                    comenzaremos con el estudio de la historia de México.',
          },
          {
            grado: 'IV',
            descripcion:
              'Aprenderemos sobre el contexto social y religioso de los diferentes\
                    pueblos que habitaron el México prehispánico, cómo era su vida y su cultura.',
          },
          {
            grado: 'V',
            descripcion:
              'Estudiaremos los orígenes del ser humano, su desarrollo\
                    en las antiguas civilizaciones alrededor del mundo y sus aportaciones,\
                    reflexionando sobre cómo podemos aprender de la historia para mejorar.',
          },
          {
            grado: 'VI',
            descripcion:
              'Estudiaremos los diferentes personajes principales de\
                    la independencia de México, su contexto social y económico,\
                    los movimientos que surgieron  y la Revolución Mexicana,\
                    también analizaremos la Europa del siglo XIX.',
          },
        ],
      },
      {
        titulo: 'Geografía y Civismo',
        grados: [
          {
            grado: 'III',
            descripcion:
              'Estudiaremos el territorio mexicano para identificar\
                    los diferentes estados, sus recursos principales, relieves y climas.',
          },
          {
            grado: 'IV',
            descripcion:
              'Estudiaremos sobre el mundo en que vivimos, los ríos,\
                    lagos, y territorios, el clima, el relieve, el sistema solar y el universo. ',
          },
          {
            grado: 'V',
            descripcion:
              'Vivimos en un extenso universo, estudiaremos su origen,\
                    el sistema solar y cómo funciona. También conoceremos nuestro continente,\
                    la formación de montañas y relieves alrededor del mundo.',
          },
          {
            grado: 'VI',
            descripcion:
              'Aprenderemos sobre la estructura socioeconómica\
                    y la constitución política de México, estudiaremos los problemas sociales\
                    y del medio ambiente locales y cómo estos se relacionan con el resto del mundo\
                    a través de la globalización.',
          },
        ],
      },
    ],
  },
  {
    nivel: 'Secundaria',
    descripcion: (
      <React.Fragment>
        {' '}
        Nuestro plan de estudios de secundaria está enfocado en desarrollar, con
        precisión y de manera integral, las habilidades necesarias e impartir
        los conocimientos totales necesarios para poder enfrentar niveles
        superores de educación.
      </React.Fragment>
    ),
    color: 'rgb(247, 195, 0)',
    secondaryColor: 'rgb(255, 213, 79)',
    img: SecundariaImg,
    imgS: SecundariaImg2,
    edad: 'De 11 a 15 años',
    costoSoporte: 900,
    Materias: [
      {
        titulo: 'Español',
        grados: [
          {
            grado: 'I',
            descripcion:
              'A partir de la lectura de diversos autores,\
                    los estudiantes desarrollan habilidades literarias\
                    para, finalmente, escribir sus propias obras.',
          },
          {
            grado: 'II',
            descripcion:
              'Acompañamos a los estudiantes a conocer y utilizar\
                    las reglas del lenguaje escrito para comunicar ideas y defenderlas\
                    de manera debidamente sustentada.',
          },
          {
            grado: 'III',
            descripcion:
              'Conoceremos los géneros literarios, los grupos y categorías\
                    en las que podemos clasificar las obras literarias\
                    y estudiaremos a los escritores que han dejado huella en el mundo.',
          },
        ],
      },
      {
        titulo: 'Matemáticas',
        grados: [
          {
            grado: 'I',
            descripcion:
              'Aritmética, Geometría y principios de Álgebra.\
                    Conjuntos, operaciones con enteros, decimales, fracciones;\
                    números negativos, ángulos, signos de agrupación;\
                    área, perímetro, volumen, capacidad, solución de problemas;\
                    ecuaciones lineales, probabilidad y ejercicios de cálculo mental.',
          },
          {
            grado: 'II',
            descripcion:
              'Aritmética, Geometría y  Álgebra.\
                    Diagramas, conjuntos, números factoriales, exponentes, raíz cuadrada;\
                    operaciones con enteros, decimales, fracciones, porcentajes, números negativos;\
                    ángulos, signos de agrupación, área, perímetro, volumen, capacidad;\
                    solución de problemas, ecuaciones lineales, probabilidad;\
                    ejercicios de cálculo mental, estimación y aproximación;\
                    análisis de datos y gráficas, uso de fórmulas matematicas;\
                    primeros pasos en excel, creación de hojas de cálculo\
                    y elaboración de formularios.',
          },
          {
            grado: 'III',
            descripcion:
              'Aritmética, Geometría, Trigonometría y  Álgebra.\
                    Diagramas, conjuntos, números factoriales, exponentes, raíz cuadrada;\
                    operaciones con enteros, decimales, fracciones, porcentajes,números negativos;\
                    ángulos, signos de agrupación, área, perímetro, volumen, capacidad;\
                    solución de problemas, ecuaciones lineales, probabilidad\
                    ejercicios de cálculo mental, estimación y aproximación;\
                    análisis de datos y gráficas, desigualdad lineal;\
                    teorema de Pitágoras, seno, coseno y tangente, problemas de trigonometría;\
                    notación estándar, problemas de proporciones, planos y coordenadas y excel.',
          },
        ],
      },
      {
        titulo: 'Ciencias Naturales',
        grados: [
          {
            grado: 'I',
            descripcion:
              'Aprendemos sobre diferentes teórias del origen del ser humano\
                    y sobre otras formas de vida que habitan con nosotros en este planeta.',
          },
          {
            grado: 'II',
            descripcion:
              'Utilizamos herramientas precisas para el estudio de los fenómenos físicos\
                    como el movimiento, la temperatura, la óptica, el magnetismo,\
                    identificando los conceptos y cada una de las leyes que los rigen.',
          },
          {
            grado: 'III',
            descripcion:
              'En nuestro planeta hay un sinfín de sustancias y reacciones\
                    que nos ayudan a entender cómo es que funciona nuestro cuerpo y el mundo,\
                    los estudiantes podrán potenciar sus conocimientos\
                    sobre las propiedades químicas de la materia.',
          },
        ],
      },
      {
        titulo: 'Ciencias Sociales',
        grados: [
          {
            grado: 'I',
            descripcion:
              'Estudiaremos las interrelaciones de las sociedades\
                    y el espacio en donde viven alrededor del mundo.\
                    Conoceremos algunos lugares tanto en su aspecto físico natural\
                    o ya habitado por el hombre. Conoceremos su cultura,\
                    población e historia.',
          },
          {
            grado: 'II',
            descripcion:
              'Llevaremos a nuestros estudiantes a conocer los acontecimientos\
                    más importantes en la historia del hombre y su desarrollo,\
                    dividiéndolos en periodos destacados y significativos de la humanidad,\
                    los cuales marcaron un antes y un después. Conocer nuestra historia familiar,\
                    como sociedad y mundial, nos ayuda a no repetir los errores del pasado.\
                    Conoceremos las antiguas civilizaciones hasta los hechos contemporáneos\
                    que nos han forjado como sociedad actual.',
          },
          {
            grado: 'III',
            descripcion:
              'Como sociedad buscamos la libertad y luchamos contra las injusticias;\
                    acompañaremos a nuestros estudiantes entre los diferentes movimientos sociales\
                    alrededor del mundo, veremos sus aciertos y errores.\
                    Iremos desde la Revolución Francesa que,\
                    con la inmensa cantidad de cambios que llevó consigo,\
                    se considera un hecho clave en la historia; pasando por dos Guerras Mundiales,\
                    la aparición de las democracias modernas,\
                    el sufragio universal y los partidos políticos',
          },
        ],
      },
    ],
  },
];

export { niveles };

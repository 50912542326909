import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';

const Terminos = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        padding: '10vh 7.5vw 10vh 7.5vw',
      }}
    >
      <Typography variant='h5' align='justify'>
        <b>
          Contrato y términos de uso de plataforma educativa que celebran, por
          una parte y en su carácter de proveedora del servicio, Educación
          Práctica para la Vida, A. C. a quien en lo sucesivo se le denominará
          “La Proveedora”, y por la otra en su carácter de usuario del servicio
          , en su carácter de padre/madre o tutor/a del estudiante de nombre ,
          quien en este carácter cursará el grado, equivalente al grado de y
          mismo a quienes en lo sucesivo se denominará “El Usuario”; contrato
          que se sujeta a las siguientes declaraciones y cláusulas:
        </b>
      </Typography>
      <br />
      <br />
      <br />
      <br />
      <Typography variant='h5' align='center'>
        DECLARACIONES
      </Typography>
      <br />
      <br />
      <br />
      <Typography variant='h5' align='justify'>
        1a.- “La Proveedora” declara ser una Asociación Civil constituida
        conforme a las leyes mexicanas relativas a las de su tipo y que,
        conforme a su objeto social ofrece a los padres educadores en el hogar,
        a través de Educazion.net International School Corp, plataforma
        educativa debidamente registrada en Miami, Florida, U.S.A, contenidos
        educativos de nivel básico para Preescolar, Primaria y Secundaria, como
        se denomina en México.
        <br />
        <br />
        2a.- Así mismo declara que es legalmente usuaria de los derechos de
        autor respecto de dichos contenidos educativos, los cuales se encuentran
        debidamente registrados y protegidos a favor de su autora por las leyes
        mexicanas, norteamericanas y tratados internacionales sobre la materia,
        por lo que hace a la propiedad intelectual de los mismos.
        <br />
        <br />
        3a.- Que dichos contenidos educativos se encuentran alojados dentro de
        la respectiva plataforma educativa denominada educazion.net a la cual
        accederá “El Usuario” y/o su hijo o pupilo, dependiendo del grado y
        nivel al que se inscriba o sea inscrito.
        <br />
        <br />
        4a.- Sigue declarando “La Proveedora” que, a fin de cumplir con su
        objeto social, ofrece sus servicios a todas las personas interesadas en
        ellos, sin distinción ni discriminación de tipo ideológico, religioso ni
        de ningún otro que “El Usuario” y/o su hijo o pupilo pudiera o no tener;
        sino que en todo tiempo y a través de sus integrantes y colaboradores
        mostrará siempre el debido soporte, respeto y consideración.
        <br />
        <br />
        5a.- Que al término del grado o ciclo que el estudiante curse, “La
        Proveedora” se compromete a entregarle a “El Usuario” las evidencias del
        desempeño de su hijo, pupilo o estudiante en el uso de dicha plataforma
        educativa, a fin de que pueda tramitar la revalidación o reconocimiento
        de validez oficial de estudios en su respectivo país de residencia; de
        conformidad con las respectivas leyes y disposiciones aplicables al caso
        concreto.
        <br />
        <br />
        6a.- Finalmente declara “La Proveedora” que, conforme a la legislación
        mexicana vigente y tratados internacionales al respecto, se compromete y
        obliga a resguardar y a no revelar los datos personales de “El Usuario”
        y/o su hijo o pupilo; salvo por mandato judicial debidamente requerido y
        en el que se cumplan las formalidades esenciales establecidas en la
        misma ley.
        <br />
        <br />
        7a.- Por su parte “El Usuario” y/o su hijo o pupilo declaran su expreso
        deseo de usar la plataforma educativa de “La Proveedora”, y que así
        mismo aceptan los términos del presente contrato en todas y cada una de
        sus partes, manifestando así mismo que conocen y aceptan en su totalidad
        el Reglamento de Uso de las Plataformas (RUP); que conocen el
        funcionamiento de las plataformas y que, en su defecto, antes de que su
        hijo o pupilo acceda a resolver los contenidos educativos y junto con
        este, se comprometen y obligan a aprender su uso apoyados en las
        explicaciones escritas, así como en los videos respectivos que para ese
        efecto se ponen a su disposición dentro del sitio web de “La
        Proveedora”, denominado educazion.net
        <br />
        <br />
        8a.- Así mismo que, junto con su hijo o pupilo, o el propio estudiante o
        “El Usuario”, pondrá todo su empeño en lograr que el aprendizaje se
        cumpla, y que para este efecto se podrá apoyar aún de material externo a
        los contenidos educativos, complementando la teoría con la práctica; tal
        y como visitas a museos, clases prácticas en el campo, visitas a plantas
        industriales, oficinas gubernamentales, etc.
        <br />
        <br />
        9a.- Del mismo modo “El Usuario” y/o su hijo o pupilo, se comprometen a
        respetar siempre a todos los demás usuarios y compañeros de estudio, así
        como a no hacer mal uso del nombre de usuario y contraseña que se les
        asigne; tal como compartirlo con otra u otras personas, ya que esto,
        conforme al Reglamento de Uso de las Plataformas, será motivo de
        expulsión de la plataforma educativa, sin derecho a reinscripción.
        <br />
        <br />
        10a.- Finalmente “El Usuario” se compromete y obliga a contar tanto con
        el equipo de cómputo requerido, así como con la conexión a internet
        suficiente para que el hijo, estudiante o pupilo pueda acceder, resolver
        e interacturar con la plataforma educativa; siendo siempre su exclusiva
        responsabilidad la falta de capacidad en uno y otro caso, y no así de
        “La Proveedora”; siendo del todo imposible volver a abrir evaluaciones o
        contenidos que el estudiante debió atender en su momento y que por
        alguna inherente no lo hizo.
        <br />
        <br />
        Ambas partes pactan de conformidad las siguientes
        <br />
        <br />
      </Typography>
      <Typography variant='h5' align='center'>
        <b>
          CLÁUSULAS
          <br />
          CAPÍTULO ÚNICO <br />
          TÉRMINOS Y CONDICIONES PARA EL USO DE LAS PLATAFORMAS DE LA
          INSCRIPCIÓN
        </b>
      </Typography>
      <br />
      <br />
      <br />
      <Typography variant='h5' align='justify'>
        DE LA INSCRIPCIÓN.
        <br />
        PRIMERA.- “El Usuario” y/o su hijo o pupilo se compromete y obliga a
        realizar personalmente el procedimiento de inscripción al cuatrimestre
        de su elección, tal y como se indica en las instrucciones; de tal manera
        que no es responsabilidad de “La Proveedora” si por alguna omisión o
        error dicho procedimiento no se concluye con éxito. Debiendo revisar
        minuciosamente y corregir lo necesario, y siendo igualmente su
        responsabilidad tanto la veracidad, así como la oportunidad de los datos
        asentados en el procedimiento. En caso de realizar el procedimiento de
        inscripción por medio de interpósita persona, “El Usuario” asume por
        completo la responsabilidad de dicho trámite para el caso de hacerlo de
        manera incorrecta.
        <br />
        <br />
        SEGUNDA.- En caso de los Centros Educativos Digitales, siempre será
        responsabilidad del encargado o Director o Directora la veracidad y
        oportunidad de los datos de sus estudiantes, así como su proceso de
        inscripción y aprendizaje; conforme al artículo que antecede. Ambos
        usuarios, tanto los estudiantes como los padres o tutores, así como los
        directores de los Centros Educativos Digitales, se comprometen a hacer
        siempre buen uso tanto de la plataforma, así como de los contenidos
        educativos del cuatrimestre al cual inscribieron a sus respectivos
        hijos, pupilos o alumnos.
        <br />
        <br />
        TERCERA.- En caso de realizar el procedimiento de inscripción y/o pago
        de la misma, o bien de mensualidad o colegiatura, o bien de recargos por
        pago extemporáneo, y después solicitar devolución de cantidades dichos
        conceptos por cualquier motivo, “El Usuario” reconoce y acepta que no
        hay devolución de cantidad alguna. Por tal razón, es su exclusiva
        responsabilidad la realización correcta de dichos procedimientos de
        pago.
        <br />
        <br />
        CUARTA.- En virtud del presente contrato, “La Proveedora” ofrece a “El
        Usuario” y/o su hijo o pupilo, así como al estudiante inscrito, el
        soporte técnico para atender todos los asunto de esa naturaleza; mismo
        servicio que se tiene que solicitar por los medios institucionales y a
        través de los cuales se brindará la atención siempre oportuna.
        <br />
        <br />
        QUINTA.- Para el caso de interpretación o controversia, las partes en el
        presente contrato señalan expresamente la competencia de los tribunales
        civiles de la ciudad de Zacatlán, Puebla, México; renunciando al fuero
        de los que pudiera corresponderles en razón de sus respectivos
        domicilios presentes o futuros; señalando precisamente Los Estrados de
        los mismos tribunales, para la notificación de acuerdos emitidos.
        <br />
        <br />
        SEXTA.- Por no tener cláusula contraria al Derecho, las partes en el
        presente contrato se obligan a estar y pasar por él en todo tiempo, como
        si se tratase de cosa juzgada, y lo firman de conformidad a los días del
        mes de , del año
        <br />
      </Typography>
      <br />
      <br />
      <br />
      <Typography variant='h5' align='center'>
        ________________
        <br />
        LICENCIADO LEOPOLDO FERNÁNDEZ ARROYO, EN SU CARÁCTER DE REPRESENTANTE
        LEGAL DE EDUCACIÓN PRÁCTICA PARA LA VIDA.
        <br />
        <br />
        <br />
        <br />
        _____________
        <br />
        NOMBRE DEL USUARIO
      </Typography>
    </div>
  );
};

export default Terminos;

import React from 'react';
import { Suspense, lazy } from 'react';
import Loading from '@material-ui/core/LinearProgress';

//Components
import Head from '../Head';
const Proceso = lazy(() => import('../subvistas/Inscripciones/Proceso'));
const ArrowDown = lazy(() => import('../componentes/utils/ArrowDown'));

function Informacion() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Head titulo='Inscripciones' />
      <Suspense fallback={<Loading />}>
        <Proceso />
        <ArrowDown />
      </Suspense>
    </React.Fragment>
  );
}

export default Informacion;

import React from 'react';

//Components
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

//Styles

const Alert = (props) => {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
};

const SnackBar = (props) => {
  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.setAlerta({ open: false, message: null, type: 'error' });
  };

  return (
    <Snackbar
      open={props.open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      test-id='snackbar'
    >
      <Alert onClose={handleClose} severity={props.type}>
        {props.children}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;

import React from 'react';
import Timer from 'react-compound-timer';

//Components
import Typography from '@material-ui/core/Typography';

function NotFound(props) {
  React.useEffect(() => {
    console.log('no se encuentra, joven', props);
    setTimeout(() => props.history.push('/'), 5000);
  }, [props]);

  return (
    <div style={{ padding: '10% 20%' }}>
      <Typography variant='h1'>:( Ups... </Typography>
      <br /> <br /> <br />
      <Typography variant='h3'>
        La pagina que intentas visitar no existe.
        <br /> En un momento seras redireccionado al inicio.
      </Typography>
      <Typography variant='h2'>
        <Timer initialTime={5100} direction='backward'>
          <Timer.Seconds />
        </Timer>
      </Typography>
    </div>
  );
}

export default NotFound;

import React from 'react';
import clsx from 'clsx';

//Components
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';

//Styles
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contenido: {
    opacity: 1,
    zIndex: 1,
    height: '100%',
    position: 'relative',
    padding: '0 10%',
    width: '100vw',
    overflow: 'hidden',
  },
}));

const Portada = ({
  titulo,
  descripcion,
  img,
  img_alt,
  bg_color,
  color,
  height,
  direction,
}) => {
  const [appear, setAppear] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    setTimeout(() => {
      setAppear(true);
    }, 250);
  }, [true]);

  return (
    <div
      className={classes.root}
      style={{
        backgroundColor: bg_color ?? 'white',
        height: height ?? '100vh',
        padding: '0% 0% 0 0%',
        overflow: 'hidden',
      }}
    >
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        className={classes.contenido}
        spacing={3}
      >
        <Grid item xs={12} sm={12} md={6} style={{ textAlign: 'center' }}>
          <Slide in={appear} timeout={350} direction='up'>
            <img alt={img_alt ?? ''} src={img} className='portada-img' />
          </Slide>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography
            variant='h1'
            style={{
              textAlign: direction ?? 'left',
              color: color ?? 'secondary',
              marginTop: '0%',
              opacity: 1,
              fontWeight: 'bolder',
            }}
            className={clsx({ hidden: !appear, shown: appear })}
            align='center'
          >
            {titulo ?? ''}
          </Typography>
          <br />
          <Typography
            variant='h6'
            style={{
              color: color ?? 'secondary',
              textAlign: direction ?? 'left',
            }}
            className={clsx({ hidden: !appear, shown: appear })}
            align='center'
          >
            {descripcion ?? ''}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Portada;

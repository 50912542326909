import React from 'react';
import { StateContext } from '../state/StateProvider';

export const handleAlerts = () => {
  const context = React.useContext(StateContext);
  const { dispatch } = context;

  const successMessage = (message) =>
    dispatch({
      type: 'ALERT',
      payload: {
        open: true,
        type: 'success',
        message: message,
      },
    });

  const warningMessage = (message) =>
    dispatch({
      type: 'ALERT',
      payload: {
        open: true,
        type: 'warning',
        message: message,
      },
    });

  const errorMessage = (message) =>
    dispatch({
      type: 'ALERT',
      payload: {
        open: true,
        type: 'error',
        message: message,
      },
    });

  return { successMessage, errorMessage, warningMessage };
};

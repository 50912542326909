import React from 'react';
import axios from 'axios';
import { Suspense, lazy } from 'react';
import Loading from '@material-ui/core/LinearProgress';

import { sesionStore } from '../../utils/sesionStore';
import Materias from '../../utils/materias';

//Components
import Typography from '@material-ui/core/Typography';
const Dialog = lazy(() => import('../../componentes/utils/Dialog'));
import Button from '../../componentes/utils/Button';
const SnackBar = lazy(() => import('../../componentes/utils/Snackbar'));
import CircularProgress from '@material-ui/core/CircularProgress';

//Styles
import { makeStyles } from '@material-ui/core/styles';

import * as store from '../../utils/localStore';
import { CloseYearForm } from '../../componentes/Cuenta/CloseYearForm';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '50vh',
    padding: '48px 24px',
    maxWidth: '1024px',
    margin: '0 auto',
    display: 'grid',
    gap: '24px',
  },
  subRoot: {
    width: '100%',
    zIndex: 1,
  },
  box: {
    padding: '0 10%',
  },
}));

const materias = {
  PRE01: Materias[0],
  PRE02: Materias[1],
  PRE03: Materias[2],
  PRI01: Materias[3],
  PRI02: Materias[4],
  PRI03: Materias[5],
  PRI04: Materias[6],
  PRI05: Materias[7],
  PRI06: Materias[8],
  SEC01: Materias[9],
  SEC07: Materias[9],
  SEC02: Materias[10],
  SEC08: Materias[10],
  SEC03: Materias[11],
  SEC09: Materias[11],
};

const getMaterias = (nivel) => materias[nivel];

function Slogan({ width, color, back }) {
  const global = React.useContext(sesionStore);
  const classes = useStyles();
  const [alert, setAlert] = React.useState({
    open: false,
    message: '',
    type: 'success',
  });
  const [dialog, setDialog] = React.useState({
    open: false,
    message: '',
    process: null,
    title: '',
  });
  const [grado, setGrado] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setGrado(getMaterias(global.sesion.nivelActual));
  }, [true]);

  function proceder(materias) {
    const nuevoHistorial = global.sesion.historialAcademico;
    nuevoHistorial.push({
      periodo: global.sesion.nivelActual,
      materias: materias,
      comentario: '',
      autorizado: false,
      token: '',
      aprobado: false,
      fecha: global.sesion.comienzaCiclo,
    });
    setLoading(true);
    axios({
      method: 'POST',
      url:
        process.env.EDUCAZION_SERVER +
        'estudiantes/cierre/' +
        global.sesion.usuario,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${store.getObject('token')}`,
      },
      data: {
        estudiante: {
          constancia: '',
          estado: '4',
          historialAcademico: nuevoHistorial,
        },
      },
    }).then((response) => {
      if (response.error) {
        setAlert({
          open: true,
          message:
            'Algo salió mal. Lo sentimos, intente mas tarde o hable con nuestro equipo de soporte.',
          type: 'error',
        });
      } else {
        setLoading(false);
        global.setSesion(response.data);
      }
    });
  }

  function handleSubmit(formData) {
    let flag = true;
    let average = 0;
    let subjects = [];
    let numberOfSubjects = 0;

    for (const [key, value] of Object.entries(formData)) {
      if (!key.includes('EXT')) {
        numberOfSubjects++;
        average += value;
        const subjectName = grado.materias.find(
          (materia) => materia.clave === key
        )?.nombre;
        subjects.push({
          nombre: subjectName,
          clave: key,
          calificacion: value,
        });
      }
    }

    if (formData.EXT01 && formData.EXT01_GRADE) {
      subjects.push({
        nombre: formData.EXT01,
        clave: 'EXT01',
        calificacion: formData.EXT01_GRADE,
      });
    }

    if (formData.EXT02 && formData.EXT02_GRADE) {
      subjects.push({
        nombre: formData.EXT02,
        clave: 'EXT02',
        calificacion: formData.EXT02_GRADE,
      });
    }

    if (flag) {
      setDialog({
        message:
          'El promedio total del curso es: ' +
          Math.round((average / numberOfSubjects) * 100) / 100 +
          ', ¿Desea proceder? Una vez iniciado el proceso ya no se puede revertir.',
        open: true,
        process: () => proceder(subjects),
        title: 'Confirmación',
      });
    } else {
      setAlert({
        open: true,
        message: 'Por favor, llene todos los campos del formulario.',
        type: 'error',
      });
    }
  }

  if (loading)
    return (
      <div
        className={classes.root}
        style={{ textAlign: 'center', height: '80vh' }}>
        <CircularProgress />
      </div>
    );

  if (global.sesion.estado == '4' || global.sesion.estado == '5')
    return (
      <div className={classes.root}>
        <Typography variant='h3'>Cierre en Proceso</Typography>
        <Typography variant='h6'>
          Uno de nuestros colaboradores está revisando tu formulario para
          corroborar las calificaciones ingresadas. En cuanto se haya terminado
          el proceso, usted podrá solicitad su constancia y revisar las
          calificaciones del ciclo escolar.
        </Typography>
        <Typography variant='body1'>¡Gracias por tu paciencia!</Typography>
        <Button onClick={back}>Regresar</Button>
      </div>
    );
  else if (grado === null) return null;
  return (
    <div className={classes.root}>
      <Suspense fallback={<Loading />}>
        <Dialog
          title={dialog.title}
          close={() =>
            setDialog({
              open: false,
              message: '',
              process: null,
              title: '',
            })
          }
          finalizar={dialog.process}
          aria-labelledby='customized-dialog-title'
          open={dialog.open}
          fullWidth={false}
          maxWidth='md'>
          {dialog.message}
        </Dialog>
        <SnackBar open={alert.open} setAlert={setAlert} type={alert.type}>
          {alert.message}
        </SnackBar>
      </Suspense>
      <Box
        borderBottom='1px solid lightgray'
        paddingBottom='24px'
        display='grid'
        gridGap='8px'>
        <Typography variant='h5' style={{ fontWeight: 700 }}>
          Cierre de Ciclo Escolar
        </Typography>
        <Typography variant='body1'>
          Por favor, llene el formulario con las calificaciones obtenidas en el
          ciclo escolar que está por cerrar.
        </Typography>
      </Box>
      <CloseYearForm
        yearTitle={grado.pretty}
        yearData={grado}
        submitFunction={handleSubmit}
      />
    </div>
  );
}

export default Slogan;

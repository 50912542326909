import React from 'react';

const sesionStore = React.createContext(null);
const { Provider } = sesionStore;

const SesionProvider = ({ children }) => {
  const [sesion, setSesion] = React.useState(null);
  const [presesion, setPresesion] = React.useState(null);
  const [intro, setIntro] = React.useState(false);
  const [curtain, setCurtain] = React.useState(false);
  const [lang, setLang] = React.useState('esp');
  // const [reactga, setReactga] = React.useState(startGA());

  return (
    <Provider
      value={{
        sesion,
        setSesion,
        presesion,
        setPresesion,
        intro,
        setIntro,
        curtain,
        setCurtain,
        lang,
        setLang,
      }}
    >
      {children}
    </Provider>
  );
};

export { sesionStore, SesionProvider };

import React from 'react';
import { BsDiscord } from 'react-icons/bs';
import { Fab, Box } from '@material-ui/core';

const openInNewTab = (url) => {
  window.open(url, '_blank', 'noreferrer');
};

const Dial = () => {
  return (
    <Fab
      variant='extended'
      color='secondary'
      onClick={() => {
        openInNewTab('https://discord.gg/4XJtuT8k2D');
      }}
      style={{
        position: 'fixed',
        bottom: '24px',
        right: '24px',
        zIndex: 1000,
      }}>
      <Box display='flex' gridColumnGap='8px' alignItems='center'>
        Contáctanos <BsDiscord />
      </Box>
    </Fab>
  );
};

export default Dial;

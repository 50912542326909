import axios from 'axios';
import * as store from '../../../utils/localStore';

export const useDocumentsActions = ({
  setLoading,
  sesion,
  setDialog,
  setAlerta,
  setShowImageAlert,
}) => {
  const downloadConstancia = async (language) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.EDUCAZION_SERVER}estudiantes/${sesion.usuario}/constancia`,
        {
          responseType: 'json',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${store.getObject('token')}`,
          },
          params: {
            idioma: language,
          },
        }
      );
      setDialog({
        open: false,
        message: '',
        process: null,
        title: '',
      });
      const hiddenElement = document.createElement('a');
      hiddenElement.href = `${process.env.EDUCAZION_SERVER}estudiantes/download/file?path=${response.data}`;
      hiddenElement.target = '_blank';
      hiddenElement.download = 'constancia_educazion.pdf';
      hiddenElement.click();
    } catch (error) {
      console.log('Error', error.response?.data?.message || error);
      setAlerta({
        open: true,
        message:
          error.response?.data?.error || 'Error al generar la constancia',
        type: 'error',
      });
      setShowImageAlert(true);
      window.scrollTo(0, 0);
    } finally {
      setLoading(false);
    }
  };

  const downloadCredencial = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.EDUCAZION_SERVER}estudiantes/${sesion.usuario}/credencial`,
        {
          responseType: 'json',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${store.getObject('token')}`,
          },
          params: {
            idioma: 'en',
          },
        }
      );
      setDialog({
        open: false,
        message: '',
        process: null,
        title: '',
      });
      const hiddenElement = document.createElement('a');
      hiddenElement.href = `${process.env.EDUCAZION_SERVER}estudiantes/download/file?path=${response.data}`;
      hiddenElement.target = '_blank';
      hiddenElement.download = 'credencial_educazion.pdf';
      hiddenElement.click();
    } catch (error) {
      console.log('Error', error.response?.data?.message || error);
      setAlerta({
        open: true,
        message:
          error.response?.data?.error || 'Error al generar la credencial',
        type: 'error',
      });
      setShowImageAlert(true);
      window.scrollTo(0, 0);
    } finally {
      setLoading(false);
    }
  };

  const downloadActa = (docSrc, matricula) => {
    const link = document.createElement('a');
    link.href = docSrc;
    link.download = `acta_${matricula}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const uploadActa = () => {
    // @ts-ignore
    let file = document.getElementById('acta').files[0];
    let formData = new FormData();
    formData.append('documento', file);
    setLoading(true);
    axios({
      method: 'POST',
      url: `${process.env.EDUCAZION_SERVER}estudiantes/${sesion?.usuario}/acta`,
      responseType: 'json',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Token ' + store.getObject('token'),
      },
    })
      .then((response) => {
        setAlerta({
          open: true,
          type: 'success',
          message: 'El acta se ha subido de manera exitosa.',
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setAlerta({
          open: true,
          message: error.response?.data?.error || 'Error al subir el acta.',
          type: 'error',
        });
      });
  };

  const ActaMenuItems = [
    {
      label: 'Descargar',
      disabled: !sesion?.actaNacimiento,
      onClick: () =>
        downloadActa(
          `${process.env.BUCKET_S3}/${sesion?.actaNacimiento}`,
          sesion?.usuario
        ),
    },
    {
      label: 'Subir PDF',
      onClick: () => {
        document.getElementById('acta').click();
      },
    },
  ];

  const ConstanciaMenuItems = [
    { label: 'Descargar en español', onClick: () => downloadConstancia('es') },
    { label: 'Descargar en inglés', onClick: () => downloadConstancia('en') },
  ];

  const CredencialMenuItems = [
    { label: 'Descargar', onClick: downloadCredencial },
  ];

  const documentsItems = {
    acta: ActaMenuItems,
    constancia: ConstanciaMenuItems,
    credencial: CredencialMenuItems,
  };

  return {
    documentsItems,
    uploadActa,
  };
};

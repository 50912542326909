import React from 'react';

//Components
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

//Styles
import { makeStyles } from '@material-ui/core/styles';

const Input = React.forwardRef((props, ref) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiInputBase-input': {
        color: props.color ? 'white' : theme.palette.secondary.dark,
        fontWeight: 'bold',
      },
      '& label': {
        color: props.color ? 'white' : theme.palette.secondary.dark,
        fontSize: '.9rem',
      },
      '& label.Mui-focused': {
        color: props.color ? 'white' : theme.palette.secondary.dark,
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '4px',
        borderWidth: 2,
        '& fieldset': {
          borderColor: props.color ? 'white' : theme.palette.secondary.dark,
          borderWidth: 2,
          borderRadius: '4px',
        },
        '&:hover fieldset': {
          borderColor: props.color ? 'white' : theme.palette.secondary.dark,
          borderRadius: '4px',
        },
        '&.Mui-focused fieldset': {
          borderColor: props.color ? 'gray' : theme.palette.secondary.dark,
        },
        '& .MuiSelect-icon': {
          color: props.color
            ? 'white !important'
            : theme.palette.secondary.main + ' !important',
        },
      },
    },
  }));

  const classes = useStyles();
  return (
    <TextField
      className={classes.root}
      {...props}
      variant='outlined'
      inputProps={{ ref: ref }}>
      {props.options
        ? props.options.map((option, i) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label ? option.label : option.value}
            </MenuItem>
          ))
        : null}
    </TextField>
  );
});

export default Input;

import axios from 'axios';
import React from 'react';
import withWidth from '@material-ui/core/withWidth';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { sesionStore } from '../utils/sesionStore';
import {
  MENU_BTN0,
  MENU_BTN1,
  MENU_BTN2,
  MENU_BTN3,
  MENU_BTN4,
} from '../languages/UI';

import { handleAlerts } from '../utils';

//Components
import {
  AppBar,
  Button,
  Collapse,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  Fade,
  Grid,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Slide,
  TextField,
  Toolbar,
  Typography,
  useScrollTrigger,
  Input,
} from '@material-ui/core';

import Dehaze from '@material-ui/icons/Dehaze';
import WhatsAppIcon from '@material-ui/icons/Phone';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import * as store from '../utils/localStore';
import { useHistory } from 'react-router-dom';

//Icons
import UserIcon from '@material-ui/icons/AccountCircle';

//Styles
import { makeStyles } from '@material-ui/core/styles';

//Images
import prepaenlinea from '../imagenes/logo.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    display: 'none',
    color: '#222222',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  phone: {
    flexGrow: 3,
    display: 'none',
    color: '#222222',
    [theme.breakpoints.up('sm')]: {
      display: 'inline',
    },
  },
  phoneIco: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  menuOption: {
    display: 'none',
    color: '#222222',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  menuOptionA: {
    fontSize: '2rem',
    // display: 'none',
    color: '#222222',
    // [theme.breakpoints.up('sm')]: {
    display: 'block',
    // }
  },
  appbar: {
    backgroundColor: 'white',
  },
  toolbar: {
    // minHeight: 84,
    minHeight: 94,
    padding: '0 5%',
  },
  container: {
    marginTop: 84,
  },
  mainLogo: {
    width: 52,
    height: 52,
    opacity: 0,
  },
  appear: {
    opacity: 1,
    transitionProperty: 'all !important',
    transitionDuration: '1.5s',
    transitionDelay: '.5s',
  },
  list: {
    width: 300,
  },
  fullList: {
    width: 'auto',
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function Header(props) {
  const [logged, setLogged] = React.useState(false);
  const [intro, setIntro] = React.useState(false);
  const [intro2, setIntro2] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const global = React.useContext(sesionStore);
  const classes = useStyles();
  const { successMessage, errorMessage } = handleAlerts();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [passwordData, setPasswordData] = React.useState({
    password: '',
    password2: '',
    currentPassword: '',
    openDialog: false,
    showPassword1: false,
    showPassword2: false,
    showCurrentPass: false,
    helperText: '',
  });

  const setOpenDialogPassword = () => {
    setPasswordData({ ...passwordData, openDialog: true });
    setAnchorEl(null);
  };

  const setCloseDialogPassword = () =>
    setPasswordData({ ...passwordData, openDialog: false });

  const handleChange = (prop) => (event) =>
    setPasswordData({ ...passwordData, [prop]: event.target.value });

  const handleClickShowPassword1 = () =>
    setPasswordData({
      ...passwordData,
      showPassword1: !passwordData.showPassword1,
    });

  const handleClickShowPassword2 = () =>
    setPasswordData({
      ...passwordData,
      showPassword2: !passwordData.showPassword2,
    });

  const handleClickShowCurrPass = () =>
    setPasswordData({
      ...passwordData,
      showCurrentPass: !passwordData.showCurrrentPass,
    });

  const errorMsg = (
    <Typography component='span' type='body2' color='secondary'>
      Las contraseñas no coinciden
    </Typography>
  );

  const handleUpdatePassword = async (event) => {
    event.preventDefault();
    if (passwordData.password !== passwordData.password2) {
      setPasswordData({ ...passwordData, helperText: errorMsg });
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.EDUCAZION_SERVER}estudiantes/password/${global.sesion.usuario}`,
        {
          newPassword: passwordData.password,
          oldPassword: passwordData.currentPassword,
        },
        {
          responseType: 'json',
          headers: { 'Content-Type': 'application/json' },
        }
      );

      successMessage(response.data.message);
      setPasswordData({
        ...passwordData,
        openDialog: false,
        helperText: null,
        currentPassword: '',
        password: '',
        password2: '',
        showPassword1: false,
        showPassword2: false,
        showCurrentPass: false,
      });
    } catch (error) {
      console.log('Error', error.response.data?.message || error);
      errorMessage(error.response.data?.message || error);
      setPasswordData({ ...passwordData, openDialog: false, helperText: null });
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  React.useEffect(() => {
    setLogged(global.sesion);
    if (!global.intro) {
      setTimeout(() => {
        setIntro(true);
        global.setIntro(true);
      }, 900);
      setTimeout(() => {
        setIntro2(true);
      }, 1200);
    }
    //setIntro(true);
  }, [global.sesion, global.intro]);

  const login = () => {
    console.log(global.sesion);
  };

  const handleCloseSession = () => {
    store.setItems('educsesion', null);
    localStorage.clear();
    global.setSesion(null);
    setAnchorEl(null);
    window.location.reload();
  };

  const handleClose = () => setAnchorEl(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const anchor = 'right';

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      style={{ backgroundColor: 'rgb(38, 198, 218)' }}
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <List>
        <NavLink to={'/inicio'}>
          <ListItem button className={classes.menuOptionA} variant='text'>
            {MENU_BTN0(global.lang)}
          </ListItem>
        </NavLink>
        <Divider />
        <NavLink to={'/informacion'}>
          <ListItem button className={classes.menuOptionA} variant='text'>
            {MENU_BTN1(global.lang)}
          </ListItem>
        </NavLink>
        <Divider />
        <NavLink to={'/curricula'}>
          <ListItem button className={classes.menuOptionA} variant='text'>
            {MENU_BTN2(global.lang)}
          </ListItem>
        </NavLink>
        <Divider />
        <NavLink to={'/inscripciones'}>
          <ListItem button className={classes.menuOptionA} variant='text'>
            {MENU_BTN3(global.lang)}
          </ListItem>
        </NavLink>
        <Divider />
        <NavLink to={'/cuenta'}>
          {logged !== null ? (
            <ListItem
              className={classes.menuOptionA}
              onClick={handleCloseSession}
              component={Button}>
              <ExitToAppIcon />
              Cerrar Sesión
            </ListItem>
          ) : (
            <ListItem
              button
              className={classes.menuOptionA}
              variant='text'
              onClick={login}>
              {MENU_BTN4(global.lang)}
            </ListItem>
          )}
        </NavLink>
      </List>
    </div>
  );

  return (
    <React.Fragment>
      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}>
        {list(anchor)}
      </Drawer>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar className={classes.appbar}>
          <Collapse in={intro} timeout={1000}>
            <Toolbar className={classes.toolbar}>
              <NavLink to={'/inicio'}>
                <Slide in={intro2} timeout={1500} direction='down'>
                  <IconButton
                    edge='start'
                    color='inherit'
                    aria-label='open drawer'>
                    <img
                      className={clsx(classes.mainLogo, {
                        [classes.appear]: intro2,
                      })}
                      src={prepaenlinea}
                    />
                  </IconButton>
                </Slide>
              </NavLink>
              <Typography className={classes.title} variant='h6' noWrap>
                educazion.net
              </Typography>
              <div className={classes.phone}>
                <WhatsAppIcon
                  fontSize='small'
                  style={{ transform: 'translateY(4px)', opacity: '.8' }}
                />
                <Typography
                  variant='body1'
                  style={{ display: 'inline', paddingBottom: 10 }}>
                  +52 (222) 454-5670{' '}
                </Typography>
              </div>
              {props.width === 'sm' || props.width === 'xs' ? (
                <React.Fragment>
                  <div style={{ paddingRight: '60px' }}>
                    <Typography variant='body1'>
                      +52 (222) 454-5670{' '}
                      <WhatsAppIcon style={{ transform: 'translateY(6px)' }} />
                    </Typography>
                  </div>
                  <IconButton
                    style={{
                      display: 'block',
                      position: 'absolute',
                      right: '5%',
                    }}
                    onClick={toggleDrawer(anchor, true)}>
                    <Dehaze fontSize='large' />
                  </IconButton>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Dialog open={passwordData.openDialog}>
                    <DialogTitle>Cambiar Contraseña</DialogTitle>
                    <DialogContent style={{ padding: 16 }}>
                      <Grid item container spacing={3} alignItems='stretch'>
                        <Grid item xs={12}>
                          <Typography variant='body1'>
                            Introduce la nueva contraseña dos veces. Si por
                            alguna razón la olvidas, comunícate con control
                            escolar para reponerla.
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant='outlined'
                            label='Introduce Tu Contraseña Actual'
                            type={
                              passwordData.showCurrentPass ? 'text' : 'password'
                            }
                            value={passwordData.currentPassword}
                            onChange={handleChange('currentPassword')}
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <IconButton onClick={handleClickShowCurrPass}>
                                    {passwordData.showCurrentPass ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant='outlined'
                            label='Introduce Nueva Contraseña'
                            type={
                              passwordData.showPassword1 ? 'text' : 'password'
                            }
                            value={passwordData.password}
                            onChange={handleChange('password')}
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <IconButton
                                    onClick={handleClickShowPassword1}>
                                    {passwordData.showPassword1 ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            helperText={passwordData.helperText}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant='outlined'
                            label='Repite Nueva Contraseña'
                            type={
                              passwordData.showPassword2 ? 'text' : 'password'
                            }
                            value={passwordData.password2}
                            onChange={handleChange('password2')}
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <IconButton
                                    onClick={handleClickShowPassword2}>
                                    {passwordData.showPassword2 ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            helperText={passwordData.helperText}
                          />
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        variant='contained'
                        color='secondary'
                        onClick={setCloseDialogPassword}>
                        Cancelar
                      </Button>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={handleUpdatePassword}>
                        Actualizar Contraseña
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Fade in={intro2} timeout={1000}>
                    <NavLink to={'/inicio'}>
                      <Button className={classes.menuOption} variant='text'>
                        {MENU_BTN0(global.lang)}
                      </Button>
                    </NavLink>
                  </Fade>
                  <Fade in={intro2} timeout={1250}>
                    <NavLink to={'/informacion'}>
                      <Button className={classes.menuOption} variant='text'>
                        {MENU_BTN1(global.lang)}
                      </Button>
                    </NavLink>
                  </Fade>
                  <Fade in={intro2} timeout={1500}>
                    <NavLink to={'/curricula'}>
                      <Button className={classes.menuOption} variant='text'>
                        {MENU_BTN2(global.lang)}
                      </Button>
                    </NavLink>
                  </Fade>
                  <Fade in={intro2} timeout={1500}>
                    <NavLink to={'/inscripciones'}>
                      <Button className={classes.menuOption} variant='text'>
                        {MENU_BTN3(global.lang)}
                      </Button>
                    </NavLink>
                  </Fade>
                  <Fade in={intro2} timeout={1000}>
                    <NavLink to={'/cuenta'}>
                      {logged !== null ? (
                        <>
                          <Button
                            variant='text'
                            onClick={handleClick}
                            startIcon={<UserIcon />}>
                            <b>{logged.nombres}</b>
                          </Button>
                          <Menu
                            id='user-options'
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            style={{ width: 270 }}>
                            <MenuItem>Hola {logged.nombres}</MenuItem>
                            <MenuItem onClick={setOpenDialogPassword}>
                              Cambiar Contraseña
                            </MenuItem>
                            <Divider />
                            <MenuItem
                              onClick={handleCloseSession}
                              style={{ width: 300 }}>
                              Cerrar Sesión
                            </MenuItem>
                          </Menu>
                        </>
                      ) : (
                        <Button
                          className={classes.menuOption}
                          variant='text'
                          onClick={login}>
                          {MENU_BTN4(global.lang)}
                        </Button>
                      )}
                    </NavLink>
                  </Fade>
                </React.Fragment>
              )}
            </Toolbar>
          </Collapse>
        </AppBar>
      </ElevationScroll>
      {/*<Toolbar />*/}
      <div className={classes.container}>{props.children}</div>
    </React.Fragment>
  );
}

export default withWidth()(Header);

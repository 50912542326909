import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import Input from '../utils/Input';
import { useFormik } from 'formik';
import * as yup from 'yup';

export const CloseYearForm = ({ yearTitle, yearData, submitFunction }) => {
  const initialValues = yearData.materias.reduce((acc, materia) => {
    acc[materia.clave] = '';
    return acc;
  }, {});
  const validationSchema = yup.object().shape(
    {
      ...yearData.materias.reduce((acc, materia) => {
        acc[materia.clave] = yup.number().required('Campo requerido');
        return acc;
      }, {}),
      EXT01: yup.string().when('EXT01_GRADE', {
        is: (value) => value,
        then: (schema) =>
          schema.required('El nombre de la materia es requerido'),
      }),
      EXT01_GRADE: yup.string().when('EXT01', {
        is: (value) => value,
        then: (schema) => schema.required('La calificación es requerida'),
      }),
      EXT02: yup.string().when('EXT02_GRADE', {
        is: (value) => value,
        then: (schema) =>
          schema.required('El nombre de la materia es requerido'),
      }),
      EXT02_GRADE: yup.string().when('EXT02', {
        is: (value) => value,
        then: (schema) => schema.required('La calificación es requerida'),
      }),
    },
    [
      ['EXT01', 'EXT01_GRADE'],
      ['EXT02', 'EXT02_GRADE'],
    ]
  );

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      EXT01: '',
      EXT01_GRADE: '',
      EXT02: '',
      EXT02_GRADE: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      submitFunction(values);
    },
  });

  return (
    <form>
      <Box display='grid' gridGap='24px'>
        <Typography variant='h6' gutterBottom>
          {yearTitle}
        </Typography>
        <Box
          display='grid'
          gridTemplateColumns='repeat(auto-fill, minmax(400px, 1fr))'
          gridGap={20}>
          {yearData.materias.map((materia) => (
            <Box
              key={materia.clave}
              display='flex'
              flexDirection='column'
              gridGap='8px'>
              <Typography
                variant='body1'
                style={{ fontWeight: 700, color: '#3EBCE8' }}>
                {materia.nombre}
              </Typography>
              <Input
                key={materia.clave}
                id={materia.clave}
                label='Calificación'
                name={materia.clave}
                select
                options={[
                  { label: '0', value: 0 },
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                ]}
                onChange={formik.handleChange}
                type='text'
                fullWidth
                value={formik.values[materia.clave]}
                error={
                  formik.touched[materia.clave] &&
                  Boolean(formik.errors[materia.clave])
                }
              />
              <Typography variant='body2' color='error'>
                {formik.touched[materia.clave] && formik.errors[materia.clave]}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box>
          <Typography variant='h6' gutterBottom>
            Cursos extracurriculares
          </Typography>
          <Typography variant='body2'>
            Si se cursó algún curso extracurricular, favor de anotar el nombre y
            la calificación
          </Typography>
        </Box>
        <Box display='grid' gridGap='24px'>
          <Box display='flex' flexDirection='column' gridGap='16px'>
            <Typography
              variant='body1'
              style={{ fontWeight: 700, color: '#3EBCE8' }}>
              Extracurricular 1
            </Typography>
            <Box display='flex' gridGap='16px'>
              <Input
                id='EXT01'
                label='Nombre del curso'
                name='EXT01'
                type='text'
                fullWidth
                value={formik.values.EXT01}
                onChange={formik.handleChange}
                error={formik.touched.EXT01 && Boolean(formik.errors.EXT01)}
              />
              <Input
                id='EXT01_GRADE'
                label='Calificación'
                name='EXT01_GRADE'
                select
                options={[
                  { label: '0', value: 0 },
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                ]}
                onChange={formik.handleChange}
                type='text'
                fullWidth
                value={formik.values.EXT01_GRADE}
                error={
                  formik.touched.EXT01_GRADE &&
                  Boolean(formik.errors.EXT01_GRADE)
                }
              />
            </Box>
            <Typography variant='body2' color='error'>
              {formik.errors.EXT01_GRADE ?? formik.errors.EXT01}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column' gridGap='16px'>
            <Typography
              variant='body1'
              style={{ fontWeight: 700, color: '#3EBCE8' }}>
              Extracurricular 2
            </Typography>
            <Box display='flex' gridGap='16px'>
              <Input
                id='EXT02'
                label='Nombre del curso'
                name='EXT02'
                type='text'
                fullWidth
                value={formik.values.EXT02}
                onChange={formik.handleChange}
                error={formik.touched.EXT02 && Boolean(formik.errors.EXT02)}
              />
              <Input
                id='EXT02_GRADE'
                label='Calificación'
                name='EXT02_GRADE'
                select
                options={[
                  { label: '0', value: 0 },
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                ]}
                onChange={formik.handleChange}
                type='text'
                fullWidth
                value={formik.values.EXT02_GRADE}
                error={
                  formik.touched.EXT02_GRADE &&
                  Boolean(formik.errors.EXT02_GRADE)
                }
              />
            </Box>
            <Typography variant='body2' color='error'>
              {formik.errors.EXT02 ?? formik.errors.EXT02_GRADE}
            </Typography>
          </Box>
        </Box>
        <Box display='flex' justifyContent='flex-end'>
          <Button
            onClick={formik.handleSubmit}
            variant='contained'
            color='primary'>
            Cerrar ciclo
          </Button>
        </Box>
      </Box>
    </form>
  );
};

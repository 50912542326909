import React from 'react';
import axios from 'axios';
import { sesionStore } from '../../utils/sesionStore';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import BootstrapButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const Button = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '1rem',
    padding: '6px 12px',
    border: '5px solid #f7c300',
    lineHeight: 1.5,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#f7c300',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#f7c300',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
})(BootstrapButton);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: '20px 70px',
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    color: '#f7c300',
  },
  closeIcon: {
    fontSize: '2rem',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography style={{ textAlign: 'center' }} variant='h3'>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
    paddingTop: 0,
    border: 'none',
    textAlign: 'center',
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit,
    textAlign: 'center',
    alignSelf: 'center',
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const global = React.useContext(sesionStore);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    props.setConfirmacion({ open: false });
  };

  const finalizar = () => {
    props.setConfirmacion({ open: false });
    setOpen(false);
    props.terminar != null
      ? props.terminar({
          importe: props.cantidad.split(' ')[1],
          moneda: props.cantidad.split(' ')[0],
          paymentId: props.id,
          paymentToken: props.referencia,
          tipoPago: 'Efectivo',
          concepto: props.concepto,
        })
      : null;
  };

  return (
    <div>
      <Dialog
        onClose={finalizar}
        aria-labelledby='customized-dialog-title'
        open={props.open}
        fullWidth={false}
        maxWidth='lg'>
        <DialogTitle id='customized-dialog-title' onClose={finalizar}>
          DATOS DE PAGO
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography variant='h5'>ID DE LA ORDEN:</Typography>
              <Typography className='response'>{props.id}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant='h5'>REFERENCIA PARA PAGAR:</Typography>
              <Typography className='response'>{props.referencia}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant='h5'>MÉTODO DE PAGO:</Typography>
              <Typography className='response'>{props.metodo}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant='h5'>CANTIDAD:</Typography>
              <Typography className='response'>{props.cantidad}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant='h5'>ORDEN:</Typography>
              <Typography className='response'>{props.concepto}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type='submit' onClick={finalizar}>
            ACEPTAR
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React from 'react';

//Components
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

//Styles
import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import createTheme from '@material-ui/core/styles/createTheme';

const theme = createTheme({
  palette: {
    primary: { main: '#f7c300' },
    secondary: { main: 'rgb(240, 133, 17)' },
  },
  overrides: {
    MuiButton: {
      contained: {
        color: 'white',
        backgroundColor: '#999ef5',
        '&:hover': {
          backgroundColor: '#6c71c8',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: '#6c71c8',
          },
        },
      },
    },
  },
});

const BootstrapButton = withStyles((theme) => ({
  root: {
    color: 'white',
    borderRadius: '40px',
    padding: '8px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: '5px 20px',
    },
    '&:hover': {
      borderWidth: '2px',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
}))(Button);

function CustomButton(props) {
  //const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <BootstrapButton
        {...props}
        color={props.color ? props.color : 'primary'}
        variant='contained'
        type={props.type ? props.type : 'button'}
        disabled={props.disabled}
        onClick={props.onClick}
        required={props.required}>
        <Typography variant='body1'>{props.children}</Typography>
      </BootstrapButton>
    </ThemeProvider>
  );
}

export default CustomButton;

import React from 'react';
import axios from 'axios';

import { Suspense, lazy } from 'react';
import Loading from '@material-ui/core/LinearProgress';

import Head from '../../Head';
import { sesionStore } from '../../utils/sesionStore';
import { NavLink as Link } from 'react-router-dom';

//Components
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '../../componentes/utils/Button';
import Input from '../../componentes/utils/Input';
import { ToDiscord } from '../../componentes/ToDiscord';

//Icons
const SnackBar = lazy(() => import('../../componentes/utils/Snackbar'));

//Styles
import { makeStyles } from '@material-ui/core/styles';
import * as store from '../../utils/localStore';

//Imagenes
import InscripcionImg from '../../imagenes/javier/15.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgb(245,245,245)',
    minHeight: '60vh',
    overflow: 'hidden',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '15%',
    },
  },
  subRoot: {
    width: '100%',
    zIndex: 1,
  },
  box: {
    padding: '0 10%',
  },
  callto: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const Slogan = () => {
  const global = React.useContext(sesionStore);
  const classes = useStyles();
  const [usuario, setUsuario] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [alert, setAlerta] = React.useState({
    open: false,
    message: '',
    type: 'Error',
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (store.getObject('userId')) {
      global.setSesion({ usuario: store.getObject('userId') });
    }
  }, []);

  const login = async (event) => {
    event.preventDefault();
    if (usuario === '') {
      setAlerta({
        open: true,
        message: 'Ingrese el usuario por favor',
        type: 'error',
      });
    } else if (password === '') {
      setAlerta({
        open: true,
        message: 'Ingrese su password por favor',
        type: 'error',
      });
    }

    try {
      const response = await axios.post(
        `${process.env.EDUCAZION_SERVER}estudiantes/login`,
        { usuario, password: btoa(password) },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      store.setItems('educsesion', {
        user: response.data.estudiante.usuario,
        pass: response.data.estudiante.password,
      });
      global.setSesion(response.data.estudiante);
      store.setObject('token', response.data.token);
      store.setObject('userId', response.data.estudiante.usuario);
    } catch (error) {
      store.setItems('educsesion', null);
      setAlerta({
        open: true,
        message: 'Su sesión se ha cerrado, por favor inicie nuevamente.',
        type: 'warning',
      });
      console.log('Error', error.response?.data?.message || error);
    }
  };

  return (
    <React.Fragment>
      <Head titulo='Inicia Sesión' />
      <Suspense fallback={<Loading />}>
        <SnackBar open={alert.open} setAlerta={setAlerta} type={alert.type}>
          {alert.message}
        </SnackBar>
      </Suspense>

      <Grid
        container
        className={classes.root}
        alignItems='center'
        justifyContent='center'>
        <Grid
          item
          xs={false}
          sm={false}
          md={7}
          className={classes.callto}
          style={{
            height: '100vh',
            textAlign: 'center',
            overflow: 'hidden',
            width: '100%',
          }}>
          <img
            src={InscripcionImg}
            style={{ marginTop: '-50%', maxHeight: '176vh' }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <form
            style={{
              padding: '0 10%',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '24px',
            }}
            onSubmit={login}>
            <div style={{ marginTop: '32px' }}>
              <Typography variant='h3'>
                <b>Inicia Sesión</b>
              </Typography>
            </div>
            <Input
              id='user'
              label='Usuario'
              name='user'
              onChange={(event) => setUsuario(event.target.value)}
              fullWidth
            />
            <Input
              id='password'
              type='password'
              label='Contraseña'
              name='password'
              onChange={(event) => setPassword(event.target.value)}
              fullWidth
            />
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Button type='submit' onClick={login}>
                Iniciar Sesión
              </Button>
            </div>
            <Typography variant='body1' align='center' color='secondary'>
              <i>
                “El único instrumento que los hombres tenemos tanto para
                perfeccionarnos como para vivir dignamente es la educación”
              </i>
              <br />- Tomás de Aquino
            </Typography>
            <Typography variant='h6' align='center'>
              ¿Deseas saber más sobre la educación en casa? Visite nuestra
              sección de{' '}
              <Link className='link' to='/informacion'>
                Información
              </Link>
              .
            </Typography>
          </form>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Slogan;

import React from 'react';
import { StateContext } from '../state/StateProvider';
import { Alert as MuiAlert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';

const CustomAlert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const Alert = () => {
  const context = React.useContext(StateContext);
  const state = context.state;
  const dispatch = context.dispatch;
  const { open, type, message } = state.snackbar;

  const handleClose = (_event, reason) => {
    if (reason === 'clickaway') return;
    dispatch({ type: 'CLOSE_ALERT' });
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <CustomAlert onClose={handleClose} severity={type}>
          {message}
        </CustomAlert>
      </Snackbar>
    </>
  );
};

export default Alert;

import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MoreVert from '@material-ui/icons/MoreVert';
import { DocumentMenu } from '../DocumentMenu';
import { useStyles } from './Documents.style';
import { useDocumentsActions } from './useDocumentsActions';

export const Documents = ({
  sesion,
  setStep,
  setConcept,
  setAlerta,
  setLoading,
  setDialog,
  setShowImageAlert,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [documentName, setDocumentName] = useState(null);
  const [menuItems, setMenuItems] = useState([]);

  const total = sesion?.estado === '3';

  const { documentsItems, uploadActa } = useDocumentsActions({
    setAlerta,
    setLoading,
    setDialog,
    sesion,
    setShowImageAlert,
  });

  const handleClick = (event, key) => {
    setAnchorEl(event.currentTarget);
    setDocumentName(key);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAcademicSupport = () => {
    setStep(2);
    setConcept('soporte');
  };
  const classes = useStyles();

  useEffect(() => {
    setMenuItems(documentsItems[documentName]);
  }, [documentName]);

  return (
    <>
      <input type='file' id='acta' onChange={uploadActa} hidden />
      <Box className={classes.academicSupport}>
        <Box className={classes.documentContainer}>
          <Typography variant='body1' className={classes.documentTitle}>
            Acta de Nacimiento
          </Typography>
          <IconButton
            style={{ padding: 0 }}
            onClick={(event) => {
              handleClick(event, 'acta');
            }}>
            <MoreVert />
          </IconButton>
        </Box>
        <Box className={classes.documentContainer}>
          <Typography variant='body1' className={classes.documentTitle}>
            Constancia
          </Typography>
          <IconButton
            style={{ padding: 0 }}
            onClick={(event) => {
              handleClick(event, 'constancia');
            }}>
            <MoreVert />
          </IconButton>
        </Box>
        <Box className={classes.documentContainer}>
          <Typography variant='body1' className={classes.documentTitle}>
            Credencial Digital
          </Typography>
          <IconButton
            style={{ padding: 0 }}
            onClick={(event) => {
              handleClick(event, 'credencial');
            }}>
            <MoreVert />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '16px' }}>
        {total && (
          <Button
            className={classes.closeCycleButton}
            onClick={() => setStep(1)}>
            Cerrar ciclo actual
          </Button>
        )}
        {sesion.conSoporte ? (
          <a
            className={classes.academicSupportLink}
            href='https://discord.gg/4XJtuT8k2D'
            target='_blank'
            rel='noreferrer'>
            <Typography
              variant='body1'
              style={{ color: '#fff', fontWeight: 'bold' }}>
              Ir al soporte académico en Discord
            </Typography>
          </a>
        ) : (
          <Button
            className={classes.academicSupportLink}
            onClick={handleAcademicSupport}>
            Adquiere soporte académico
          </Button>
        )}
      </Box>
      <DocumentMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        handleClose={handleClose}
        items={menuItems}
      />
    </>
  );
};

import React from 'react';

import clsx from 'clsx';
import SwipeableViews from 'react-swipeable-views';

//Components
import Head from '../Head';
import Divider from '@material-ui/core/Divider';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { niveles as curricula } from '../utils/curricula';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

//Images
import PrepaImg from '../imagenes/logoprepa.png';
import Portada from '../imagenes/banner02.png';

//Styles
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tabText: {
    [theme.breakpoints.up('xl')]: {
      fontSize: '2em',
      fontWeight: 'bold',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.75em',
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.25em',
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2em',
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1em',
      fontWeight: 'bold',
    },
  },
}));

function Curricula() {
  const [appear, setAppear] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [value2, setValue2] = React.useState(0);
  const [max, setMax] = React.useState(['Emocional', 'Intelectual']);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    if (newValue == 1) setMax(['I', 'II', 'III', 'IV', 'V', 'VI']);
    else if (newValue == 2) setMax(['I', 'II', 'III']);
    else setMax(['Emocional', 'Intelectual']);
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    if (index == 1) setMax(['I', 'II', 'III', 'IV', 'V', 'VI']);
    else if (index == 2) setMax(['I', 'II', 'III']);
    else setMax(['Emocional', 'Intelectual']);
    setValue(index);
  };

  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };

  const handleChangeIndex2 = (index) => {
    setValue2(index);
  };

  const checkIfGrado = (grado, lista, v) => {
    console.log('grado', grado);
    console.log('lista', lista);
    console.log('VAL', v);

    var newMaterias = [];

    if (lista && v > 0) {
      lista.map((materia) => {
        materia.grados.map((g) => {
          if (g.grado == grado)
            newMaterias.push({
              grado: materia.titulo + ' ' + grado,
              descripcion: g.descripcion,
            });
        });
        // if(materia.)
      });
      console.log('nm', newMaterias);
      return newMaterias;
    }

    for (var i in lista) {
      if (lista[i].titulo == grado) return lista[i].grados;
    }
    return [];
  };

  const getHead = (v) => {
    if (v == 1) return ['I', 'II', 'III', 'IV', 'V', 'VI'];
    else if (v == 2) return ['I', 'II', 'III'];
    else return ['Emocional', 'Intelectual'];
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setAppear(true);
    }, 250);
  }, [true]);

  return (
    <React.Fragment>
      <Head titulo='Currícula' />
      <Grid
        container
        style={{
          padding: '5% 5% 0% 5%',
          minHeight: '70vh',
          backgroundColor: 'rgb(84 89 169)',
        }}
        alignItems='center'>
        <Grid item xs={12} sm={12} md={6}>
          <Slide in={appear} timeout={350} direction='down'>
            <img src={Portada} style={{ maxWidth: '662px', width: '100%' }} />
          </Slide>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography
            variant='h2'
            style={{ color: 'white' }}
            className={clsx({ hidden: !appear, shown: appear })}>
            <b>Niveles Educativos</b>
          </Typography>
          <Typography
            variant='h6'
            style={{ color: 'white' }}
            className={clsx({ hidden: !appear, shown: appear })}>
            Conoce nuestro plan de estudios. Sólo da click en alguno de los
            niveles educativos para saber más.
          </Typography>
        </Grid>
      </Grid>
      <div className={clsx({ hidden: !appear, shown: appear })}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          style={{ padding: '20px 0' }}
          centered>
          <Tab label='Preescolar' className={classes.tabText} />
          <Tab label='Primaria' className={classes.tabText} />
          <Tab label='Secundaria' className={classes.tabText} />
        </Tabs>
        <SwipeableViews
          axis={'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
          style={{ backgroundColor: 'rgb(245,245,245)' }}>
          {curricula.map((nivel, index) => (
            <Grid container style={{ padding: '50px 100px' }}>
              <Grid
                item
                xs={12}
                md={6}
                lg={12}
                style={{ paddingBottom: '50px' }}>
                <img src={nivel.img} style={{ width: '100%' }} />
              </Grid>
              <Grid item xs={12} md={6} lg={12}>
                <Typography variant='h3'>{nivel.nivel}</Typography>
                <br />
                <Typography variant='body1' className='subtitle'>
                  {nivel.edad}
                </Typography>
                <br />
                <Typography variant='h6'>{nivel.descripcion}</Typography>
                <br />
                <Divider />
                <br />
                <Typography variant='h5'>Plan de Estudios</Typography>
                <br />
                <Typography variant='h6' className='subtitle'>
                  Seleccione el grado que desea ver
                </Typography>
                <br />
                <React.Fragment>
                  <Tabs
                    value={value2}
                    onChange={handleChange2}
                    indicatorColor='secondary'
                    textColor='secondary'>
                    {getHead(value).map((grado) => (
                      <Tab label={'Grado ' + grado} />
                    ))}
                  </Tabs>
                  <br />
                  <br />
                  <SwipeableViews
                    axis={'x'}
                    index={value2}
                    onChangeIndex={handleChangeIndex2}>
                    {getHead(value).map((grado, index2) => {
                      return (
                        <div>
                          <Typography variant='h6'>
                            <b>Grado {grado}</b>
                          </Typography>
                          <Grid container style={{ maxWidth: '100%' }}>
                            {value == 0
                              ? checkIfGrado(
                                  grado,
                                  nivel.Habilidades,
                                  index
                                ).map((materia) => (
                                  <Grid item>
                                    <br />
                                    <Typography
                                      variant='body1'
                                      className='subtitle'>
                                      <b>{materia.grado}</b>
                                    </Typography>
                                    <Typography variant='body1'>
                                      {materia.descripcion}
                                    </Typography>
                                  </Grid>
                                ))
                              : checkIfGrado(grado, nivel.Materias, index).map(
                                  (materia) => (
                                    <Grid item>
                                      <br />
                                      <Typography
                                        variant='body1'
                                        className='subtitle'>
                                        <b>{materia.grado}</b>
                                      </Typography>
                                      <Typography variant='body1'>
                                        {materia.descripcion}
                                      </Typography>
                                    </Grid>
                                  )
                                )}
                          </Grid>
                        </div>
                      );
                    })}
                  </SwipeableViews>
                </React.Fragment>
              </Grid>
            </Grid>
          ))}
        </SwipeableViews>
        <br />
        <br />
        <Grid
          container
          spacing={4}
          alignItems='center'
          justifyContent='center'
          style={{ width: '100%' }}>
          <Grid item xs={12} sm={5} md={4} style={{ textAlign: 'center' }}>
            <a href='https://prepaenlinea.mx' target='_blank'>
              <img
                src={PrepaImg}
                style={{ width: '250px', height: '250px', borderRadius: '50%' }}
              />
            </a>
          </Grid>
          <Grid item xs={12} sm={7} md={8} style={{ textAlign: 'left' }}>
            <a className='link' href='https://prepaenlinea.mx'>
              <Typography variant='h5'>https://prepaenlinea.mx</Typography>
            </a>
            <Typography variant='h6'>
              Visita nuestra página web oficial y conoce nuestro
              programa educativo para bachillerato (nivel medio superior).
            </Typography>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default Curricula;

import axios from 'axios';

const backend = process.env.EDUCAZION_SERVER + 'estudiantes/';

export const PRESIGN = (data) => {
  return axios.post(`${backend}presign`, data);
};

export const UPLOAD_PHOTO = (data, matricula, config) => {
  return axios.post(`${backend}${matricula}/portrait`, data, config);
};

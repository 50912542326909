import React from 'react';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import BootstrapButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const Button = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '1rem',
    padding: '6px 12px',
    border: '5px solid #f7c300',
    lineHeight: 1.5,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#f7c300',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#f7c300',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
})(BootstrapButton);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: '20px 70px',
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    color: '#f7c300',
  },
  closeIcon: {
    fontSize: '2rem',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography style={{ textAlign: 'center' }} variant='h3'>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
    paddingTop: 0,
    border: 'none',
    textAlign: 'center',
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 1,
    textAlign: 'center',
    alignSelf: 'center',
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const [loading, setLoading] = React.useState(false);
  const handleClose = () => {
    props.setDialog({ open: false });
  };

  const finalizar = () => {
    if (!loading) {
      setLoading(true);
      props.setDialog({ open: false });
      props.terminar != null ? props.terminar() : null;
    }
  };

  if (loading)
    return (
      <div
        className={classes.root}
        style={{ textAlign: 'center', height: '80%', paddingTop: '39%' }}
      >
        <CircularProgress />
      </div>
    );

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={props.open}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          {props.titulo}
          <br />
        </DialogTitle>
        {loading ? (
          <div />
        ) : (
          <React.Fragment>
            <DialogContent dividers>
              <Typography>{props.contenido}</Typography>
            </DialogContent>
            <DialogActions>
              <Button disabled={loading} onClick={finalizar}>
                {props.accion}
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      </Dialog>
    </div>
  );
}

import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Seccion from '../../componentes/Cuenta/Seccion';
import Typography from '@material-ui/core/Typography';

import { sesionStore } from '../../utils/sesionStore';

const Pagos = ({ expanded, handleChange, width, estados, setStep }) => {
  const global = React.useContext(sesionStore);
  const { sesion } = global;
  return (
    <Seccion
      title='Historial de Pagos'
      id='2'
      expanded={expanded}
      onChange={handleChange}>
      <div style={{ width: '100%' }}>
        <Typography variant='h5'>Intentos de Pago</Typography>
        <br />
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={4} md={3}>
            <b>Fecha</b>
          </Grid>

          {width === 'sm' || width === 'xs' ? null : (
            <Grid item xs={3}>
              <b>Id</b>
            </Grid>
          )}
          <Grid item xs={3} md={2}>
            <b>Cantidad</b>
          </Grid>
          <Grid item xs={3} md={2}>
            <b>Moneda</b>
          </Grid>
          <Grid item xs={1} md={2}>
            <b>{width === 'sm' || width === 'xs' ? '' : 'Estatus'}</b>
          </Grid>
        </Grid>
        {sesion?.intentosPago?.length > 0
          ? sesion?.intentosPago?.map((intento, index) => {
              const date = new Date(intento.fecha);
              const fecha =
                date.getDate() +
                '/' +
                (date.getMonth() + 1) +
                '/' +
                date.getFullYear();
              return (
                <Grid
                  container
                  justifyContent='center'
                  alignItems='center'
                  key={index}>
                  <Grid item xs={4} md={3}>
                    {fecha}
                  </Grid>
                  {width === 'sm' || width === 'xs' ? null : (
                    <Grid item xs={3}>
                      {intento.orderId}
                    </Grid>
                  )}
                  <Grid item xs={3} md={2}>
                    {intento.cantidad}
                  </Grid>
                  <Grid item xs={2}>
                    {intento.moneda}
                  </Grid>
                  <Grid item xs={3} md={2}>
                    {estados[index] === 'loading' ? (
                      <CircularProgress color='secondary' />
                    ) : (
                      estados[index]
                    )}
                  </Grid>
                </Grid>
              );
            })
          : ''}
        <Divider />
        <br />
        <br />
        <Typography variant='h5'>Cargos</Typography>
        <br />
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={4} md={3}>
            <b>Fecha</b>
          </Grid>
          {width === 'sm' || width === 'xs' ? null : (
            <Grid item xs={3}>
              <b>Concepto</b>
            </Grid>
          )}
          <Grid item xs={4} md={3}>
            <b>Cantidad</b>
          </Grid>
          <Grid item xs={4} md={3}>
            <b>Moneda</b>
          </Grid>
        </Grid>
        {sesion?.historialPagos?.cargos.length > 0
          ? sesion?.historialPagos?.cargos.map((cargo, index) => {
              const date = new Date(cargo.fecha);
              const fecha =
                date.getDate() +
                '/' +
                (date.getMonth() + 1) +
                '/' +
                date.getFullYear();
              return (
                <Grid
                  container
                  justifyContent='center'
                  alignItems='center'
                  key={`${cargo.concepto}/${index}`}>
                  <Grid item xs={4} md={3}>
                    {fecha}
                  </Grid>
                  {width === 'sm' || width === 'xs' ? null : (
                    <Grid item xs={3}>
                      {cargo.concepto}
                    </Grid>
                  )}
                  <Grid item xs={4} md={3}>
                    {cargo.cantidad}
                  </Grid>
                  <Grid item xs={4} md={3}>
                    {cargo.moneda}
                  </Grid>
                </Grid>
              );
            })
          : 'No se ha realizado ningún cargo todavía.'}
      </div>
    </Seccion>
  );
};

export default Pagos;

import React from 'react';
import { Helmet } from 'react-helmet';

function Head(props) {
  return (
    <Helmet>
      <title>
        {props.titulo ? `Educazion.net - ${props.titulo}` : 'Educazion.net'}
      </title>
    </Helmet>
  );
}

export default Head;

import React from 'react';
import {
  HashRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import { Suspense, lazy } from 'react';
import Loading from '@material-ui/core/LinearProgress';

import { SesionProvider } from './utils/sesionStore.js';

import { StateProvider } from './state/StateProvider';

//Views
import Home from './vistas/Home';
import Informacion from './vistas/Informacion';
import Curricula from './vistas/Curricula';
import Inscripciones from './vistas/Inscripciones';
import Cuenta from './vistas/Cuenta';
import Maintenance from './vistas/Maintenance';
import Terminos from './vistas/Terminos';
import AvisoPrivacidad from './vistas/AvisoPrivacidad';
import NotFound from './404';

//Components
import Head from './Head';
import Header from './componentes/Header';
import Alert from './componentes/Alert';
const Footer = lazy(() => import('./componentes/Footer'));
import Dial from './componentes/Dial';
import CssBaseline from '@material-ui/core/CssBaseline';

//Theme
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';

function App() {
  React.useEffect(() => {
    import('react-gtm-module').then((TagManager) => {
      TagManager.initialize({
        gtmId: 'GTM-M3X3Q8Z',
      });
    });
  });

  return process.env.MAINTENANCE ? (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Maintenance />
    </ThemeProvider>
  ) : (
    <StateProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Router>
          <Head />
          <SesionProvider>
            <Header>
              <Switch>
                <Route path='/inicio' component={Home} />
                <Route path='/informacion' component={Informacion} />
                <Route path='/curricula' component={Curricula} />
                <Route path='/inscripciones' component={Inscripciones} />
                <Route path='/cuenta' component={Cuenta} />
                <Route path='/terminos-y-condiciones' component={Terminos} />
                <Route
                  path='/aviso-de-privacidad'
                  component={AvisoPrivacidad}
                />
                <Route path='/' exact>
                  <Redirect to='/inicio' component={Home} />
                </Route>
                <Route path='*' component={NotFound} />
              </Switch>
            </Header>
            <Dial />
            <Alert />
          </SesionProvider>
          <Suspense fallback={<Loading />}>
            <Footer />
          </Suspense>
        </Router>
      </ThemeProvider>
    </StateProvider>
  );
}

export default App;

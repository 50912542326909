import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  infoPContainer: {
    display: 'flex',
    alignItems: 'center',
    gridColumnGap: '8px',
    color: '#6E6779',
  },
  academicSupport: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '16px',
    marginBottom: '16px',
    '@media (min-width: 1024px)': {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
  academicSupportLink: {
    backgroundColor: '#f08511',
    padding: '8px 16px',
    borderRadius: '8px',
    color: '#fff',
    fontWeight: 'bold',
  },
  closeCycleButton: {
    backgroundColor: '#f7c300',
    padding: '8px 16px',
    borderRadius: '8px',
    color: '#fff',
    fontWeight: 'bold',
  },
  uploadButton: {
    backgroundColor: '#3EBCE8',
    padding: '8px 16px',
    borderRadius: '8px',
    color: '#fff',
    fontWeight: 'bold',
  },
  documentTitle: {
    fontWeight: 'bold',
  },
  documentContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    background: 'white',
    padding: '16px',
    borderRadius: '12px',
    justifyContent: 'space-between',
    border: '1px solid #EAE9EC',
  },
}));

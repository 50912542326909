import React from 'react';
import { sesionStore } from '../../utils/sesionStore';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '../utils/Button';
import Input from '../utils/Input';
import Typography from '@material-ui/core/Typography';

require('conekta-card-tokenizer');
import visa from '../../imagenes/visa.svg';
import mastercard from '../../imagenes/mastercard.svg';
import amex from '../../imagenes/american-express.svg';

Conekta.setPublicKey(process.env.CONEKTA_KEY);

const styles = (theme) => ({});

const MetodosPago = (props) => {
  const global = React.useContext(sesionStore);
  const [nombre, setNombre] = React.useState('');
  const [apellido, setApellido] = React.useState('');
  const [noTarjeta, setNoTarjeta] = React.useState('');
  const [fechaMes, setFechaMes] = React.useState('');
  const [fechaAnio, setFechaAnio] = React.useState('');
  const [cvv, setCvv] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [telefono, setTelefono] = React.useState('');

  const { classes } = props;

  React.useEffect(() => {
    if (process.env.TEST) {
      setNombre('Jesús');
      setApellido('Guillén Yparrea');
      setEmail('jesus.guilleny@gmail.com');
      setTelefono('5579128754');
      setNoTarjeta('5204167372494195');
      setFechaMes('02');
      setFechaAnio('2025');
      setCvv('494');
    }
  }, [props.moneda, props.concepto, props.cantidad]);

  function tokenizeCard(event) {
    setLoading(true);
    event.preventDefault();
    var data = {
      card: {
        number: noTarjeta,
        name: nombre + ' ' + apellido,
        exp_year: fechaAnio,
        exp_month: fechaMes,
        cvc: cvv,
      },
    };
    Conekta.Token.create(data, successHandler, errorHandler);
  }

  const successHandler = function (token) {
    axios({
      method: 'POST',
      url: process.env.EDUCAZION_SERVER + 'conekta/tarjeta/',
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        formulario: {
          nombrecompleto: nombre + ' ' + apellido,
          matricula: global.sesion.usuario ? global.sesion.usuario : 'na',
          concepto: props.concepto,
          email: email,
          telefono: telefono,
          token: token.id,
          conSoporte: props.soporte,
          curriculaLaica: props.laica,
          pago: {
            importe: props.cantidad,
            moneda: props.moneda,
          },
        },
      },
    }).then((response) => {
      setLoading(false);
      if (response.data.http_code) {
        console.log('ERROR');
        var mess = '';
        response.data.details.map((error) => {
          mess += error.message + ' ';
        });
        props.setDialog({
          open: true,
          titulo: 'ERROR',
          contenido: mess,
          accion: 'ACEPTAR',
          terminar: props.fail,
        });
      } else if (response.data.payment_status == 'paid') {
        console.log('PAID');
        props.after({
          importe: response.data.amount / 100,
          moneda: response.data.currency,
          id: response.data.id,
          estado: 'PAGADO',
          paymentId: response.data.id,
          paymentToken: response.data.id,
          tipoPago: 'Tarjeta',
          concepto: props.concepto,
        });
        props.setDialog({
          open: true,
          titulo: 'PAGO EXITOSO',
          contenido: 'El pago se ha procesado exitosamente.',
          accion: 'ACEPTAR',
          concepto: props.concepto,
          terminar: props.success,
        });
      } else {
        console.log('WAIT');
        props.after({
          importe: response.data.amount / 100,
          moneda: response.data.currency,
          id: response.data.id,
          estado: 'ESPERA',
          paymentId: response.data.id,
          paymentToken: response.data.id,
          tipoPago: 'Tarjeta',
          concepto: props.concepto,
        });
        props.setDialog({
          open: true,
          titulo: 'PAGO EN PROCESO',
          contenido:
            'El pago se está procesando, recibirá una notifiación cuando sea exitoso.',
          accion: 'ACEPTAR',
          concepto: props.concepto,
          terminar: props.success,
        });
      }
    });
  };

  var errorHandler = function (err) {
    /* err keys: object, type, message, message_to_purchaser, param, code */ 0;
    console.log(err);
    props.setDialog({
      open: true,
      titulo: 'ERROR DE PAGO',
      contenido: err.message_to_purchaser,
      accion: 'VOLVER',
      terminar: null,
    });
  };

  return (
    <div style={{ width: '100%' }}>
      {loading ? (
        <div style={{ position: 'absolute', left: 'calc(50% - 20px)' }}>
          <CircularProgress />
        </div>
      ) : (
        <form
          className={classes.form}
          onSubmit={(event) => tokenizeCard(event)}>
          <Typography variant='h6' style={{ textAlign: 'right' }}>
            Se aceptan tarjetas de débito y crédito:
          </Typography>
          <Typography variant='caption' style={{ textAlign: 'right' }}>
            <img style={{ width: '50px', margin: '0px 5px' }} src={visa} />
            <img
              style={{ width: '50px', margin: '0px 5px' }}
              src={mastercard}
            />
            <img style={{ width: '50px', margin: '0px 5px' }} src={amex} />
          </Typography>
          <Typography variant='caption'>*CAMPOS OBLIGATORIOS</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h4'>DATOS DEL TARJETAHABIENTE</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input
                required
                type='text'
                id='standard-name'
                label='NOMBRE(S)'
                name='nombre'
                fullWidth
                value={nombre}
                onChange={(event) => setNombre(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input
                required
                type='text'
                id='standard-p'
                label='APELLIDOS'
                name='apellidos'
                fullWidth
                value={apellido}
                onChange={(event) => setApellido(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input
                required
                type='text'
                id='standard-m'
                label='EMAIL'
                name='email'
                fullWidth
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input
                required
                type='text'
                id='standard-t'
                label='TELÉFONO'
                name='telefono'
                fullWidth
                value={telefono}
                onChange={(event) => setTelefono(event.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h3'>*DATOS DE PAGO</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Input
                required
                type='text'
                id='standard-ta'
                label='NÚMERO DE TARJETA'
                name='tarjeta'
                fullWidth
                value={noTarjeta}
                onChange={(event) => setNoTarjeta(event.target.value)}
              />
            </Grid>
            <Grid item xs={6} sm={3} md={2} lg={2}>
              <Input
                required
                type='text'
                id='standard-me'
                label='MES'
                name='mes'
                fullWidth
                value={fechaMes}
                onChange={(event) => setFechaMes(event.target.value)}
              />
            </Grid>
            <Grid item xs={6} sm={3} md={2} lg={2}>
              <Input
                required
                type='text'
                id='standard-ta'
                label='AÑO'
                name='mes'
                fullWidth
                value={fechaAnio}
                onChange={(event) => setFechaAnio(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Input
                required
                type='text'
                id='standard-ta'
                label='CVV'
                name='cvv'
                fullWidth
                value={cvv}
                onChange={(event) => setCvv(event.target.value)}
              />
            </Grid>
          </Grid>
          <div style={{ textAlign: 'right', marginTop: '30px' }}>
            <Button type='submit'>SIGUIENTE</Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default withStyles(styles)(MetodosPago);

import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  link: {
    color: '#EF8411',
    fontWeight: 700,
  },
}));

export const ToDiscord = () => {
  const classes = useStyles();
  return (
    <Box>
      <Typography>
        ¿Necesitas ayuda? Escríbenos en{' '}
        <a
          href='https://discord.gg/4XJtuT8k2D'
          target='_blank'
          className={classes.link}>
          Discord
        </a>
      </Typography>
    </Box>
  );
};

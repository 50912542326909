import React from 'react';
import { sesionStore } from '../../utils/sesionStore';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/Accordion';
import MuiExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import MuiExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

import Menos from '@material-ui/icons/Remove';
import Mas from '@material-ui/icons/Add';
import Button from '../../componentes/utils/Button';
import PorTarjeta from './porTarjeta';
import EnEfectivo from './enEfectivo';
import Dialogo from './dialogo';
import Finalizacion from './finalizacion';
import Confirmacion from './confirmacion';
import ConfirmacionSP from './confirmacionSP';
import ConfirmacionT from './confirmacionT';

import Box from '@material-ui/core/Box';

const styles = (theme) => ({
  doc: {
    display: 'grid',
    gap: '16px',
    padding: '24px 0',
  },
  topblue: {
    height: '50px',
    backgroundColor: '#0096ff',
  },
  blue: {
    minHeight: '100px',
    backgroundColor: '#0096ff',
  },
  bottomblue: {
    minHeight: '250px',
    backgroundColor: '#0096ff',
    marginBottom: '30px',
  },
  root: {
    width: '100%',
  },
  pagoText: {
    fontWeight: 'bolder',
    fontSize: '20px',
    margin: '60px',
    marginLeft: '40vw',
    width: '50vw',
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      margin: '20px 5vw 30px 5vw',
      width: '90vw',
    },
  },
  heading: {
    width: '100%',
    textAlign: 'right',
    fontWeight: 'bolder',
    fontSize: '2.5rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  titulo: {
    width: '100%',
    textAlign: 'right',
    fontWeight: 'bolder',
  },
  yellow: {
    color: '#f7c300',
    fontSize: '35px',
  },
});

const ExpansionPanel = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    paddingTop: '30px',
  },
}))(MuiExpansionPanelDetails);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'white',
    border: '5px solid #f7c300',
    marginBottom: -1,
    minHeight: 0,
    '&$expanded': {
      minHeight: 0,
    },
  },
  content: {
    margin: '5px',
    '&$expanded': {
      margin: '5px',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const MetodosPago = (props) => {
  const [moneda, setMoneda] = React.useState('MXN');
  const [cantidad, setCantidad] = React.useState(0);
  const [expanded, setExpanded] = React.useState(null);
  const global = React.useContext(sesionStore);

  const { classes, concepto } = props;

  const [dialog, setDialog] = React.useState({
    open: false,
    titulo: '',
    contenido: '',
    accion: '',
    terminar: null,
  });
  const [finalizacion, setFinalizacion] = React.useState({
    open: false,
    titulo: '',
    contenido: '',
    accion: '',
    terminar: null,
  });
  const [confirmacion, setConfirmacion] = React.useState({
    open: false,
    id: '',
    referencia: '',
    metodo: '',
    concepto: '',
    cantidad: 0,
    terminar: null,
  });
  const [confirmacionT, setConfirmacionT] = React.useState({
    open: false,
    id: '',
    referencia: '',
    metodo: '',
    concepto: '',
    cantidad: 0,
    terminar: null,
  });
  const [confirmacionSP, setConfirmacionSP] = React.useState({
    open: false,
    id: '',
    referencia: '',
    metodo: '',
    concepto: '',
    cantidad: 0,
    terminar: null,
  });

  const handleChange = (panel) => (_event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const assignPriceByAcademicLevel = () => {
    if (props?.curso?.includes('PRE')) {
      return 500;
    }
    if (props?.curso?.includes('PRI')) {
      return 700;
    }
    if (props?.curso?.includes('SEC')) {
      return 900;
    }
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);

    const currencyToLowerCase = global.sesion?.direccion?.country
      .toLowerCase()
      .trim();

    const moneda =
      currencyToLowerCase.includes('mexico') ||
      currencyToLowerCase.includes('méxico')
        ? 'MXN'
        : 'USD';

    const cantidad =
      moneda === 'MXN' ? process.env.PRECIO_MXN : process.env.PRECIO_USD;

    const finalAmount =
      concepto === 'soporte' ? assignPriceByAcademicLevel() : cantidad;

    const finalCurrency = concepto === 'soporte' ? 'MXN' : moneda;

    setMoneda(finalCurrency);
    setCantidad(Number(finalAmount));
  }, [global]);

  return (
    <Box
      display='grid'
      gridGap='24px'
      maxWidth='900px'
      margin='0 auto'
      padding='24px 0'>
      <Box display='flex' width='100%' justifyContent='end'>
        <Button
          onClick={() => {
            props.back();
            props.setConcept(null);
          }}>
          Regresar
        </Button>
      </Box>
      <Box
        display='grid'
        gridGap='8px'
        padding='32px'
        borderRadius='8px'
        style={{ background: '#FFF' }}>
        <Typography variant='h3'>Resumen de pago</Typography>
        <Typography variant='h5'>
          La cantidad a pagar es: ${cantidad} {moneda}
        </Typography>
        {concepto === 'soporte' && (
          <Typography variant='body1' style={{ color: '#6E6779' }}>
            * El soporte académico tendrá una duración de 30 días a partir de la
            fecha de pago.
          </Typography>
        )}
      </Box>
      <Box
        display='grid'
        gridGap='8px'
        padding='32px'
        borderRadius='8px'
        style={{ background: '#FFF' }}>
        <Typography variant='h3'>Métodos de pago</Typography>
        <Typography variant='h5'>
          Todos los pagos son procesados por{' '}
          <a
            target='_blank'
            href='https://www.conekta.com/'
            style={{ textDecoration: 'underline' }}>
            conekta
          </a>
        </Typography>
        <div className={classes.doc}>
          <ExpansionPanel
            expanded={expanded === 'tarjeta'}
            onChange={handleChange('tarjeta')}>
            <ExpansionPanelSummary
              expandIcon={
                expanded === 'tarjeta' ? (
                  <Menos className={classes.yellow} />
                ) : (
                  <Mas className={classes.yellow} />
                )
              }
              aria-controls='panel1a-content'
              id='panel1a-header'>
              <Typography variant='h3'>PAGOS POR TARJETA</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <PorTarjeta
                concepto={concepto + '/' + global.sesion.usuario}
                cantidad={cantidad}
                moneda={moneda}
                setDialog={setDialog}
                setFinalizacion={setFinalizacion}
                finalizacion={finalizacion}
                dialog={dialog}
                setConfirmacion={setConfirmacionT}
                confirmacion={confirmacionT}
                laica={props.laica}
                soport={props.soporte}
                success={props.success}
                after={props.after}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === 'efectivo'}
            onChange={handleChange('efectivo')}>
            <ExpansionPanelSummary
              expandIcon={
                expanded === 'efectivo' ? (
                  <Menos className={classes.yellow} />
                ) : (
                  <Mas className={classes.yellow} />
                )
              }
              aria-controls='panel2a-content'
              id='panel2a-header'>
              <Typography variant='h3'>PAGOS EN EFECTIVO</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <EnEfectivo
                concepto={concepto + '/' + global.sesion.usuario}
                cantidad={cantidad}
                moneda={moneda}
                setDialog={setDialog}
                dialog={dialog}
                setConfirmacion={setConfirmacion}
                confirmacion={confirmacion}
                laica={props.laica}
                soport={props.soporte}
                success={props.success}
                after={props.after}
                fail={() => {
                  setDialog({
                    open: false,
                    titulo: '',
                    contenido: '',
                    accion: '',
                    terminar: null,
                  });
                }}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        <Dialogo
          setDialog={setDialog}
          open={dialog.open}
          titulo={dialog.titulo}
          contenido={dialog.contenido}
          accion={dialog.accion}
          terminar={dialog.terminar}
        />
        <Finalizacion
          setDialog={setFinalizacion}
          open={dialog.open}
          titulo={dialog.titulo}
          contenido={dialog.contenido}
          accion={dialog.accion}
          terminar={dialog.terminar}
        />
        <Confirmacion
          setConfirmacion={setConfirmacion}
          cantidad={confirmacion.cantidad}
          open={confirmacion.open}
          id={confirmacion.id}
          referencia={confirmacion.referencia}
          metodo={confirmacion.metodo}
          concepto={confirmacion.concepto}
          terminar={confirmacion.terminar}
        />
        <ConfirmacionT
          setConfirmacion={setConfirmacionT}
          cantidad={confirmacionT.cantidad}
          open={confirmacionT.open}
          id={confirmacionT.id}
          referencia={confirmacionT.referencia}
          metodo={confirmacionT.metodo}
          concepto={confirmacionT.concepto}
          terminar={confirmacionT.terminar}
        />
        <ConfirmacionSP
          setConfirmacion={setConfirmacionSP}
          cantidad={confirmacionSP.cantidad}
          open={confirmacionSP.open}
          id={confirmacionSP.id}
          referencia={confirmacionSP.referencia}
          metodo={confirmacionSP.metodo}
          concepto={confirmacionSP.concepto}
          terminar={confirmacionSP.terminar}
        />
      </Box>
    </Box>
  );
};

export default withStyles(styles)(MetodosPago);

import React from 'react';
import { sesionStore } from '../utils/sesionStore';

//Componentes
import Login from '../subvistas/Cuenta/Login';
import Portada from '../subvistas/Cuenta/Portada';

function Slogan() {
  const global = React.useContext(sesionStore);
  const [logged, setLogged] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setLogged(global.sesion != null);
  }, [global.sesion]);

  return <React.Fragment>{!logged ? <Login /> : <Portada />}</React.Fragment>;
}

export default Slogan;

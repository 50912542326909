import * as R from 'ramda';
import React from 'react';
import Head from '../../Head';

import axios from 'axios';
import withWidth from '@material-ui/core/withWidth';
import { sesionStore } from '../../utils/sesionStore';

import { NavLink as Link } from 'react-router-dom';
import * as store from '../../utils/localStore';

//Components
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '../../componentes/utils/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core//ListItem';
import ListItemText from '@material-ui/core//ListItemText';
import SnackBar from '../../componentes/utils/Snackbar';
import Cierre from './Cierre';
import MetodosPago from '../../componentes/Pagos/metodosPago';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import Dialog from '../../componentes/utils/Dialog';
import Materias from '../../utils/materias';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';

//Styles
import { makeStyles } from '@material-ui/core/styles';

import Fotografia from './Fotografia';
import DatosGenerales from './DatosGenerales';
import Historial from './Historial';
import Pagos from './Pagos';
import { Documents } from './Documents/Documents';
import { IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Warning from '@material-ui/icons/Warning';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'rgb(250 250 250',
    minHeight: '60vh',
    padding: '48px',
    maxWidth: '1280px',
    margin: '0 auto',
  },
  subRoot: {
    width: '100%',
    zIndex: 1,
  },
  box: {
    padding: '0 10%',
  },
  paymentsMethodWrapper: {
    padding: '40px 32px',
  },
}));

const grade = {
  PRE01: '1er Grado de Preescolar',
  PRE02: '2do Grado de Preescolar',
  PRE03: '3er Grado de Preescolar',
  PRI01: '1er Grado de Primaria (1ro)',
  PRI02: '2do Grado de Primaria (2do)',
  PRI03: '3er Grado de Primaria (3ro)',
  PRI04: '4to Grado de Primaria (4to)',
  PRI05: '5to Grado de Primaria (5to)',
  PRI06: '6to Grado de Primaria (6to)',
  SEC01: '1er Grado de Secundaria (7mo)',
  SEC07: '1er Grado de Secundaria (7mo)',
  SEC02: '2do Grado de Secundaria (8vo)',
  SEC08: '2do Grado de Secundaria (8vo)',
  SEC03: '3er Grado de Secundaria (9no)',
  SEC09: '3er Grado de Secundaria (9no)',
};

const getGrade = (nivel) => grade[nivel];

const materias = {
  PRE01: Materias[0],
  PRE02: Materias[1],
  PRE03: Materias[2],
  PRI01: Materias[3],
  PRI02: Materias[4],
  PRI03: Materias[5],
  PRI04: Materias[6],
  PRI05: Materias[7],
  PRI06: Materias[8],
  SEC01: Materias[9],
  SEC07: Materias[9],
  SEC02: Materias[10],
  SEC08: Materias[10],
  SEC03: Materias[11],
  SEC09: Materias[11],
};

const getMaterias = (nivel) => materias[nivel];

const paymentStatus = {
  paid: <b style={{ color: '#4caf50' }}>Pagado</b>,
  pending_payment: <b style={{ color: '#ffc107' }}>Pendiente</b>,
  declined: <b style={{ color: '#f44336' }}>Declinado</b>,
  expired: <b style={{ color: '#795548' }}>Expirado</b>,
  refunded: <b style={{ color: '#0288d1' }}>Reembolsado</b>,
};

const getPaymentStatus = (status) => {
  const payment = paymentStatus[status];
  return payment ?? <b style={{ color: '#757575' }}>Desconocido</b>;
};

const Slogan = ({ width }) => {
  const global = React.useContext(sesionStore);
  const [sesion, setSesion] = React.useState(null);
  const [fecha, setFecha] = React.useState(null);
  const [fechaI, setFechaI] = React.useState(null);
  const [estados, setEstados] = React.useState(['loading']);
  const classes = useStyles();
  const [alerta, setAlerta] = React.useState({
    open: false,
    message: '',
    type: 'Error',
  });
  const [showImageAlert, setShowImageAlert] = React.useState(false);

  const [step, setStep] = React.useState(0);
  const [grado, setGrado] = React.useState('');
  const [reload, setReload] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [popover, setPopover] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popOverOpen, setPopOverOpen] = React.useState(false);
  const [paymentConcept, setPaymentConcept] = React.useState(null);
  const [dialog, setDialog] = React.useState({
    open: false,
    message: '',
    process: null,
    title: '',
  });

  const [expandAcademicHistory, setExpandAcademicHistory] =
    React.useState(true);
  const [expandPaymentHistory, setExpandPaymentHistory] = React.useState(true);

  const history = useHistory();

  const formatDate = (date) =>
    `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

  React.useEffect(() => {
    const date = new Date(global.sesion.fecha_creacion);
    const datei = new Date(global.sesion?.comienzaCiclo);
    setFecha(formatDate(date));
    setFechaI(global.sesion.comienzaCiclo ? formatDate(datei) : null);
    setGrado(getGrade(global.sesion.nivelActual));
  }, [sesion]);

  React.useEffect(() => {
    const get = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.EDUCAZION_SERVER}estudiantes/search/${global.sesion.usuario}`,
          {
            responseType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${store.getObject('token')}`,
            },
          }
        );
        global.setSesion(response.data.estudiante);
        setSesion(response.data.estudiante);
        const date = new Date(global.sesion.fecha_creacion);
        const datei =
          global.sesion.comienzaCiclo === null
            ? null
            : new Date(global.sesion.comienzaCiclo);
        const getInfoIntento = async (intento) => {
          const res = await axios.get(
            `${process.env.EDUCAZION_SERVER}conekta/${intento.orderId}`,
            {
              responseType: 'json',
              headers: { 'Content-Type': 'application/json' },
            }
          );
          return getPaymentStatus(res.data?._json?.payment_status);
        };
        const newEstados = await Promise.all(
          R.map((intento) => getInfoIntento(intento))(
            global.sesion.intentosPago
          )
        );
        setEstados(newEstados);
        setFecha(formatDate(date));

        setFechaI(datei === null ? null : formatDate(datei));
        setAlerta({
          open: true,
          message: 'Bienvenid@, ' + global.sesion.nombres,
          type: 'success',
        });
        setGrado(getGrade(global.sesion.nivelActual));
      } catch (error) {
        console.log('Error', error.response.data?.message || error);
      }
    };
    setLoading(true);
    get();
    setLoading(false);
  }, [reload]);

  React.useEffect(() => {
    setPopOverOpen(Boolean(anchorEl));
  }, [anchorEl]);

  const success = (pago) => {
    setStep(0);
    setReload(!reload);
  };

  const fail = () => {
    setAlerta({
      open: true,
      message: 'No se ha realizado ningun cargo.',
      type: 'error',
    });
    setStep(0);
    setReload(!reload);
  };

  const next = async (pago) => {
    try {
      const nuevosIntentos = [
        ...global.sesion.intentosPago,
        {
          fecha: new Date(),
          concepto: pago.concepto,
          cantidad: pago.importe,
          orderId: pago.id,
          moneda: pago.moneda,
        },
      ];
      const response = await axios.post(
        `${process.env.EDUCAZION_SERVER}estudiantes/${global.sesion.usuario}`,
        {
          estudiante: {
            intentosPago: nuevosIntentos,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${store.getObject('token')}`,
          },
          responseType: 'json',
        }
      );
      global.setSesion(response.data);
    } catch (error) {
      console.log('Error', error.response.data?.message || error);
    }
  };

  const handleMessage = (event, comentario) => {
    setAnchorEl(event.currentTarget);
    setPopover(
      <div style={{ minHeight: '150px', padding: '20px' }}>
        <Typography>{comentario}</Typography>
      </div>
    );
  };

  const handleMaterias = (event, ciclo) => {
    setAnchorEl(event.currentTarget);
    const Lista = (
      <Box padding='8px 4px 24px'>
        <Box display='flex' justifyContent='end' alignItems='center'>
          <IconButton onClick={() => setAnchorEl(null)}>
            <Close />
          </IconButton>
        </Box>
        <Box padding='0 24px' width='100%' display='grid' gridGap='16px'>
          <Typography variant='h6' style={{ fontWeight: 700 }}>
            Calificaciones {ciclo.periodo}
          </Typography>
          <Box>
            {ciclo.materias.map((materia, index) => (
              <Box
                key={`${materia.nombre}/${index}`}
                display='flex'
                alignItems='center'
                padding='8px'
                justifyContent='space-between'
                borderBottom='1px solid #E0E0E0'
                gridGap='32px'>
                <Typography
                  variant='body1'
                  style={{ fontWeight: 700, color: '#3bacd4' }}>
                  {materia.nombre}
                </Typography>
                <Typography variant='body1'>{materia.calificacion}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    );
    setPopover(Lista);
  };

  const aprobar = async (i) => {
    try {
      const nuevoHistorial = global.sesion.historialAcademico;
      nuevoHistorial[i].aprobado = true;
      setLoading(true);
      const response = await axios.post(
        `${process.env.EDUCAZION_SERVER}estudiantes/aprueba/${global.sesion.usuario}`,
        {
          estudiante: {
            estado: '1',
            historialAcademico: nuevoHistorial,
            nivelActual: null,
          },
        },
        {
          responseType: 'json',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${store.getObject('token')}`,
          },
        }
      );
      global.setSesion(response.data);
      setDialog({
        open: false,
        message: '',
        process: null,
        title: '',
      });

      setLoading(false);
    } catch (error) {
      console.log('Error', error.response.data?.message || error);
    }
  };

  const cerrarSesion = () => {
    store.setItems('educsesion', null);
    global.setSesion(null);
    history.push('/cuenta');
  };

  const validState = {
    N: <span style={{ color: '#ff5722' }}>No Válida</span>,
    S: <span style={{ color: '#4caf50' }}>Válida</span>,
  };

  const getState = (state) => {
    return (
      validState[state] ?? <span style={{ color: '#9e9e9e' }}>No Revisada</span>
    );
  };

  const downloadBoleta = async ({ grado, idioma }) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.EDUCAZION_SERVER}estudiantes/${global.sesion.usuario}/boleta`,
        {
          responseType: 'json',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${store.getObject('token')}`,
          },
          params: {
            idioma,
            grado,
            firma: true,
          },
        }
      );
      setDialog({
        open: false,
        message: '',
        process: null,
        title: '',
      });
      const hiddenElement = document.createElement('a');
      hiddenElement.href = `${process.env.EDUCAZION_SERVER}estudiantes/download/file?path=${response.data}`;
      hiddenElement.target = '_blank';
      hiddenElement.download = 'constancia_educazion.pdf';
      hiddenElement.click();
    } catch (error) {
      console.log('Error', error.response?.data?.message || error);
      setAlerta({
        open: true,
        message: error?.response?.data?.error || 'Error al generar la boleta',
        type: 'error',
      });
      setShowImageAlert(true);
      window.scrollTo(0, 0);
    } finally {
      setLoading(false);
      setAnchorEl(null);
    }
  };

  const openDownloadOptions = (event, ciclo) => {
    setAnchorEl(event.currentTarget);

    let Lista = (
      <List>
        <ListItem
          button
          onClick={(e) =>
            downloadBoleta({ grado: ciclo?.periodo, idioma: 'es' })
          }>
          <ListItemText primary='Español' />
        </ListItem>
        <ListItem
          button
          onClick={(e) =>
            downloadBoleta({ grado: ciclo?.periodo, idioma: 'en' })
          }>
          <ListItemText primary='Inglés' />
        </ListItem>
      </List>
    );
    setPopover(Lista);
  };

  if (loading)
    return (
      <div
        className={classes.root}
        style={{ textAlign: 'center', height: '80%', paddingTop: '39%' }}>
        <CircularProgress />
      </div>
    );

  if (step === 1) return <Cierre back={() => setStep(0)} />;
  else if (step === 2)
    return (
      <div className={classes.paymentsMethodWrapper}>
        <Head titulo='Pagos' />
        <MetodosPago
          setConcept={setPaymentConcept}
          concepto={paymentConcept ?? global.sesion.nivelActual}
          success={success}
          back={() => setStep(0)}
          fail={fail}
          after={next}
          curso={global.sesion.nivelActual}
          soporte={global.sesion.conSoporte}
        />
      </div>
    );

  return sesion !== null ? (
    <div className={classes.root}>
      {showImageAlert && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 24px',
            background: '#f44336',
            borderRadius: '4px',
            marginBottom: '24px',
          }}>
          <Typography
            variant='body1'
            style={{ textAlign: 'center', fontWeight: 'bold', color: '#FFF' }}>
            Vuelve a subir la fotografía del estudiante e intenta descargar el
            documento de nuevo.
          </Typography>
          <IconButton
            onClick={() => setShowImageAlert(false)}
            style={{ color: '#FFF' }}>
            <Close />
          </IconButton>
        </Box>
      )}
      <Head titulo='Cuenta' />
      <Dialog
        title={dialog.title}
        close={() =>
          setDialog({
            open: false,
            message: '',
            process: null,
            title: '',
          })
        }
        finalizar={dialog.process}
        aria-labelledby='customized-dialog-title'
        open={dialog.open}
        fullWidth={false}
        maxWidth='md'>
        {dialog.message}
      </Dialog>
      <SnackBar open={alerta.open} setAlerta={setAlerta} type={alerta.type}>
        {alerta.message}
      </SnackBar>
      <Popover
        open={popOverOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        {popover}
      </Popover>
      <Typography
        variant='h5'
        style={{ textAlign: 'center', fontWeight: 'bold', color: '#1D1B20' }}>
        {`${sesion.nombres} ${sesion.apellidoP} ${sesion.apellidoM}`}
      </Typography>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      <Box
        display='grid'
        justifyItems='center'
        gridGap='32px'
        marginBottom='24px'>
        <Fotografia setAlerta={setAlerta} />
        <DatosGenerales sesion={sesion} fecha={fecha} />
      </Box>
      {sesion.nivelActual === null ? (
        <Typography variant='h6' color='secondary'>
          No estás inscrito a ninguna currícula actualmente, por favor,
          inscríbete
          <Link to='/inscripciones' className='link'>
            {' '}
            aquí.
          </Link>
        </Typography>
      ) : (
        <Box display='grid' gridGap='32px' width='100%' marginBottom='32px'>
          <Box
            display='flex'
            flexWrap='wrap'
            justifyContent='center'
            alignItems='center'
            gridGap='16px'>
            <Typography variant='body1'>
              Actualmente estás cursando: <b>{grado}</b>
            </Typography>
            {fechaI ? (
              <Typography variant='body1'>
                Comenzaste el día: <b>{fechaI}</b>
              </Typography>
            ) : (
              <Box
                padding='8px'
                bgcolor='error.main'
                borderRadius='4px'
                display='flex'
                gridGap='8px'
                alignItems='center'>
                <Warning style={{ color: '#FFF' }} />
                <Typography variant='body1' style={{ color: '#FFF' }}>
                  Avisa a control escolar para que te asignen un comienzo de
                  ciclo
                </Typography>
              </Box>
            )}
          </Box>
          <Documents
            sesion={sesion}
            setStep={setStep}
            setConcept={setPaymentConcept}
            setDialog={setDialog}
            setAlerta={setAlerta}
            setLoading={setLoading}
            setShowImageAlert={setShowImageAlert}
          />
        </Box>
      )}
      <div style={{ margin: 16 }} />
      <Historial
        expanded={expandAcademicHistory}
        handleChange={() => {
          setExpandAcademicHistory(!expandAcademicHistory);
        }}
        width={width}
        handleMaterias={handleMaterias}
        handleMessage={handleMessage}
        openDownloadOptions={openDownloadOptions}
        setDialog={setDialog}
        aprobar={aprobar}
      />
      <Pagos
        expanded={expandPaymentHistory}
        handleChange={() => {
          setExpandPaymentHistory(!expandPaymentHistory);
        }}
        width={width}
        estados={estados}
        setStep={setStep}
      />
      <div style={{ margin: 16 }} />

      <Grid
        container
        style={{ width: '100%' }}
        spacing={2}
        alignItems='center'
        justifyContent='center'>
        <Grid item xs={12} sm={4} style={{ textAlign: 'center' }}>
          <Button onClick={() => cerrarSesion()}>Cerrar Sesión</Button>
        </Grid>
        <Grid item xs={12} sm={4} style={{ textAlign: 'center' }} id='here'>
          <a href='https://aulas.educazion.net/' target='_blank'>
            <Button>Ir a Plataforma</Button>
          </a>
        </Grid>
      </Grid>
    </div>
  ) : (
    <div className={classes.root} />
  );
};

export default withWidth()(Slogan);

//Fonts
import QuicksandTtf from './fuentes/Quicksand/Quicksand-Regular.ttf';
import createTheme from '@material-ui/core/styles/createTheme';

const quicksand = {
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Quicksand'),
    local('Quicksand-Regular'),
    url(${QuicksandTtf}) format('ttf')
  `,
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#f7c300',
      light: '#3dbeea',
    },
    secondary: {
      main: '#f08511',
      dark: 'rgb(40,40,40)',
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'Lato',
      'Source',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [quicksand],
      },
    },
  },
});

theme.typography.h1 = {
  color: '#434343',
  lineHeight: '1.167',
  fontWeight: '300',
  fontFamily: 'Poppins',
  [theme.breakpoints.up('xl')]: {
    fontSize: '4rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '3.5rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '3rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.5rem',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '2rem',
  },
};

theme.typography.h2 = {
  color: '#434343',
  lineHeight: '1.2',
  fontFamily: 'Poppins',
  [theme.breakpoints.down('xl')]: {
    fontSize: '3.5rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '3rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '2.5rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '1.75rem',
  },
};

theme.typography.h3 = {
  color: '#434343',
  lineHeight: '1.2',
  fontFamily: 'Poppins',
  [theme.breakpoints.down('xl')]: {
    fontSize: '3rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '2.5rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.75rem',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '1.5rem',
  },
};

theme.typography.h4 = {
  color: '#434343',
  lineHeight: '1.235',
  fontWeight: '400',
  fontFamily: 'Poppins',
  [theme.breakpoints.down('xl')]: {
    fontSize: '2.5rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.75rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '1.25rem',
  },
};

theme.typography.h5 = {
  color: '#434343',
  lineHeight: '1.334',
  fontWeight: '400',
  fontFamily: 'Poppins',
  [theme.breakpoints.down('xl')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.75rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.25rem',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '1rem',
  },
};

theme.typography.h6 = {
  color: '#434343',
  fontSize: '1.25rem',
  lineHeight: '1.6',
  fontWeight: '500',
  fontFamily: 'Poppins',
  [theme.breakpoints.down('xl')]: {
    fontSize: '1.75rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.25rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '0.875rem',
  },
};

theme.typography.body1 = {
  color: '#434343',
  fontSize: '1rem',
  lineHeight: '1.6',
  fontWeight: '500',
  fontFamily: 'Quicksand, sans-serif',
  [theme.breakpoints.down('xl')]: {
    fontSize: '1.25rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.125rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.875rem',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '0.8125rem',
  },
};

theme.typography.caption = {
  color: '#434343',
  fontSize: '0.875rem',
  lineHeight: '1.66',
  fontWeight: '400',
  fontFamily: 'Quicksand, sans-serif',
  [theme.breakpoints.down('md')]: {
    fontSize: '0.875rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8125rem',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '0.75rem',
  },
};

export default theme;

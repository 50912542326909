import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Email, Phone, AssignmentInd } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  infoPContainer: {
    display: 'flex',
    alignItems: 'center',
    gridColumnGap: '8px',
    color: '#6E6779',
  },
}));

const DatosGenerales = ({ sesion, fecha }) => {
  const classes = useStyles();

  return (
    <Box display='grid' gridGap='24px' width='100%'>
      <Typography
        variant='body1'
        style={{ textAlign: 'center', fontWeight: 'bold' }}>
        Estudiante desde {fecha}
      </Typography>
      <Box
        display='flex'
        flexWrap='wrap'
        gridGap='32px'
        maxWidth='1024px'
        justifyContent='center'
        alignItems='center'
        margin='0 auto'>
        <Box className={classes.infoPContainer}>
          <Email />
          <Typography variant='body1'>{sesion.emailPersonal}</Typography>
        </Box>
        <Box className={classes.infoPContainer}>
          <Phone />
          <Typography variant='body1'>{sesion.telefono}</Typography>
        </Box>
        <Box className={classes.infoPContainer}>
          <AssignmentInd />
          <Typography variant='body1'>{sesion.usuario}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DatosGenerales;

import React from 'react';
import { Menu, MenuItem, styled } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },
}));

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: '#fff',
  },
}));

export const DocumentMenu = ({ open, anchorEl, handleClose, items }) => {
  return (
    <StyledMenu
      id='personal-documents-menu'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      style={{ boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)' }}>
      {items?.map((item) => (
        <MenuItem
          key={item.label}
          onClick={item.onClick}
          disabled={item.disabled}>
          {item.label}
        </MenuItem>
      ))}
    </StyledMenu>
  );
};

import React from 'react';
import { sesionStore } from '../../utils/sesionStore';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Button from '../utils/Button';
import Input from '../utils/Input';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import datalogic from '../../imagenes/oxxopay_brand.png';

const styles = (theme) => ({
  form: {
    width: '100%',
  },
  grid: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: '12.5vh',
      paddingBottom: '7.5vh',
      marginLeft: '50px',
      marginRight: '50px',
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  formControlLabel: {
    fontSize: '1.2rem',
    fontWeight: '800',
  },
  textField: {
    width: '90%',
    color: '#f08511',
  },
  cssFocused: {},
  cssLabel: {
    '&$cssFocused': {
      color: '#f08511',
    },
  },
  cssOutlinedInput: {
    // color : "#f08511",
    '&$cssFocused $notchedOutline': {
      color: '#f08511',
      borderColor: '#f08511',
      borderWidth: '6px',
      fontSize: '1.2rem',
      fontWeight: '800',
    },
  },
  notchedOutline: {
    borderColor: '#f08511 !important',
    borderWidth: '6px',
    fontSize: '1.2rem',
    fontWeight: '800',
  },
  bootstrapRoot: {
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  checkbox: {
    color: '#f08511',
    '&$checked': {
      color: '#f08511',
    },
  },
  checked: {
    color: '#f08511',
    '&$checked': {
      color: '#f08511',
    },
  },
  forth: {
    position: 'relative',
    bottom: '5px',
    width: 'fit-content',
    [theme.breakpoints.down('xs')]: {
      bottom: '-13px',
    },
  },
  flechass: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '100px',
    },
  },
  seccion: {
    marginTop: '30px',
  },
  title: {
    color: '#424242',
    fontWeight: 'bolder',
    fontSize: '1.75rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
});

const MetodosPago = (props) => {
  const global = React.useContext(sesionStore);
  const [nombre, setNombre] = React.useState('');
  const [apellido, setApellido] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [telefono, setTelefono] = React.useState('');

  const { classes } = props;

  React.useEffect(() => {
    if (process.env.TEST) {
      setNombre('Jesús');
      setApellido('Guillén Yparrea');
      setEmail('jesus.guilleny@gmail.com');
      setTelefono('5579128754');
    }
  }, [global.sesion]);

  function submit(event) {
    event.preventDefault();
    props.setDialog({
      open: true,
      titulo: 'CONFIRMACIÓN DE PAGO',
      contenido: '¿Desea enviar la petición de pago?',
      accion: 'ACEPTAR',
      terminar: successHandler,
    });
    setLoading(true);
  }

  var successHandler = function () {
    setLoading(true);
    axios({
      method: 'POST',
      url: process.env.EDUCAZION_SERVER + 'conekta/efectivo/',
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        formulario: {
          nombrecompleto: nombre + ' ' + apellido,
          concepto: props.concepto,
          email: email,
          telefono: telefono,
          conSoporte: props.soporte,
          curriculaLaica: props.laica,
          matricula: global.sesion.usuario ? global.sesion.usuario : 'na',
          pago: {
            importe: props.cantidad,
            moneda: props.moneda,
          },
        },
      },
    }).then((response) => {
      setLoading(false);
      if (response.data.http_code) {
        var mess = '';
        response.data.details.map((error) => {
          mess += error.message + ' ';
        });
        props.setDialog({
          open: true,
          titulo: 'ERROR',
          contenido: mess,
          accion: 'ACEPTAR',
          terminar: props.fail,
        });
      } else {
        props.after({
          importe: response.data.amount / 100,
          moneda: response.data.currency,
          id: response.data.id,
          estado: 'ESPERA',
          paymentId: response.data.id,
          paymentToken: response.data.charges.data[0].payment_method.reference,
          tipoPago: 'Pago en Efectivo',
          concepto: props.concepto,
        });
        props.setConfirmacion({
          cantidad: props.moneda + ' ' + props.cantidad + '.00',
          open: true,
          id: response.data.id,
          referencia: response.data.charges.data[0].payment_method.reference,
          metodo: 'Pago en Efectivo',
          concepto: props.concepto,
          terminar: props.success,
        });
      } // props.setDialog({open: true, titulo: "PAGO EXITOSO", contenido: "", accion: "ACEPTAR", terminar : null})
    });
    /* token keys: id, livemode, used, object */
  };

  var errorHandler = function (err) {
    /* err keys: object, type, message, message_to_purchaser, param, code */
    console.log(err);
  };

  return (
    <div style={{ width: '100%' }}>
      {loading ? (
        <div style={{ position: 'absolute', left: 'calc(50% - 20px)' }}>
          <CircularProgress />
        </div>
      ) : (
        <form className={classes.form} onSubmit={(event) => submit(event)}>
          <Typography variant='h6' style={{ textAlign: 'right' }}>
            Para su comodidad, los pagos en efectivo se realizan en cualquier
            tienda OXXO del país (Sólo para pagos en México).
          </Typography>
          <Typography variant='caption' style={{ textAlign: 'right' }}>
            <img width='150px' src={datalogic} />
          </Typography>
          <Typography variant='caption'>*CAMPOS OBLIGATORIOS</Typography>
          <Grid container>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} className={classes.title}>
                  <Typography variant='h4'>DATOS DEL ESTUDIANTE</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Input
                    required
                    type='text'
                    id='standard-name'
                    label='NOMBRE(S)'
                    name='nombre'
                    fullWidth
                    value={nombre}
                    onChange={(event) => setNombre(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Input
                    required
                    type='text'
                    id='standard-p'
                    label='APELLIDOS'
                    name='apellidos'
                    fullWidth
                    value={apellido}
                    onChange={(event) => setApellido(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Input
                    required
                    type='text'
                    id='standard-m'
                    label='EMAIL'
                    name='email'
                    fullWidth
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Input
                    required
                    type='text'
                    id='standard-t'
                    label='TELÉFONO'
                    name='telefono'
                    fullWidth
                    value={telefono}
                    onChange={(event) => setTelefono(event.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <div style={{ textAlign: 'right', marginTop: '30px' }}>
              <Button fullWidth type='submit'>
                SIGUIENTE
              </Button>
            </div>
          </Grid>
        </form>
      )}
    </div>
  );
};

export default withStyles(styles)(MetodosPago);
